import { DatasetEntry, DatasetDef } from "../../core/data-manager/type/dataset";

export const name: string = "Sample Dataset";

export const outcomeNode: DatasetEntry[] = [
  { id: 1, name: "Step Count 1", value: false },
  { id: 2, name: "Weather 1", value: false },
  { id: 3, name: "Step Count 2", value: false },
  { id: 4, name: "Weather 2", value: false },
  { id: 5, name: "Step Count 3", value: false },
  { id: 6, name: "Weather 3", value: false },
  { id: 7, name: "Step Count 4", value: false },
  { id: 8, name: "Weather 4", value: false },
  { id: 0, name: "Weather 5", value: false },
];

export const relevanceNode: DatasetEntry[] = [
  { id: 1, name: "Step Count 1", value: true },
  { id: 2, name: "Weather 1", value: true },
  { id: 3, name: "Step Count 2", value: true },
  { id: 4, name: "Weather 2", value: true },
  { id: 5, name: "Step Count 3", value: false },
  { id: 6, name: "Weather 3", value: false },
  { id: 7, name: "Step Count 4", value: false },
  { id: 8, name: "Weather 4", value: false },
  { id: 9, name: "Step Count 5", value: false },
  { id: 10, name: "Weather 5", value: false },
  { id: 11, name: "Weather 7", value: false },
];

export const timeGrain: DatasetEntry[] = [
  { id: 1, name: "One Day", value: false },
  { id: 2, name: "One Week", value: false },
  { id: 3, name: "One Month", value: false },
  { id: 4, name: "One Year", value: false },
];

export const datasetRes: DatasetDef = {
  name: name,
  outcome_node: outcomeNode,
  relevance_node: relevanceNode,
  prior: relevanceNode,
  time_grain: timeGrain,
};

export const datasetReq: any = {
  name: "Test Dataset",
  outcome_node: [
    { id: 1, value: true },
    { id: 2, value: false },
    { id: 3, value: false },
  ],
  relevance_node: [
    { id: 1, value: true },
    { id: 2, value: true },
    { id: 3, value: false },
  ],
  prior: [
    { id: 1, value: true },
    { id: 2, value: false },
    { id: 3, value: false },
  ],
  time_grain: [
    { id: 1, value: true },
    { id: 2, value: false },
    { id: 3, value: false },
  ],
};
