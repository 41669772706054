import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analysisList: [],
  datasetList: [],
  graphModelList: [],
  datasetDetails: {},
  columnCount: {},
  dwh: {},
  relevanceRanking: {},
  generatedDatasetDetails: {},
  generatedModelDetails: {},
  userCountInModel: 0,
  columnsUsers: {},
  outcomeColumns: {},
  relevanceColumns: { relevance_columns_count_list: [] },
};

export const infoDispatchSlice = createSlice({
  name: "infoDispatch",
  initialState,
  reducers: {
    setInfoDispatchAnalysisList: (state, action) => {
      state.analysisList = action.payload;
    },
    setInfoDispatchDatasetList: (state, action) => {
      state.datasetList = action.payload;
    },
    setInfoDispatchGraphModelList: (state, action) => {
      state.graphModelList = action.payload;
    },
    setInfoDispatchDatasetDetails: (state, action) => {
      state.datasetDetails = action.payload;
    },
    setInfoDispatchColumnCount: (state, action) => {
      state.columnCount = action.payload;
    },
    setInfoDispatchDWH: (state, action) => {
      state.dwh = action.payload;
    },
    setInfoDispatchRelevanceRanking: (state, action) => {
      state.relevanceRanking = action.payload;
    },
    setInfoDispatchGeneratedDatasetDetails: (state, action) => {
      state.generatedDatasetDetails = action.payload;
    },
    setInfoDispatchGeneratedModelDetails: (state, action) => {
      state.generatedModelDetails = action.payload;
    },
    setInfoDispatchUserCountInModel: (state, action) => {
      state.userCountInModel = action.payload;
    },
    setInfoDispatchColumnsUsers: (state, action) => {
      state.columnsUsers = action.payload;
    },
    setInfoDispatchOutcomeColumns: (state, action) => {
      state.outcomeColumns = action.payload;
    },
    setInfoDispatchRelevanceColumns: (state, action) => {
      state.relevanceColumns = action.payload;
    },
  },
});

export const {
  setInfoDispatchAnalysisList,
  setInfoDispatchDatasetList,
  setInfoDispatchGraphModelList,
  setInfoDispatchDatasetDetails,
  setInfoDispatchColumnCount,
  setInfoDispatchDWH,
  setInfoDispatchRelevanceRanking,
  setInfoDispatchGeneratedDatasetDetails,
  setInfoDispatchGeneratedModelDetails,
  setInfoDispatchUserCountInModel,
  setInfoDispatchColumnsUsers,
  setInfoDispatchOutcomeColumns,
  setInfoDispatchRelevanceColumns,
} = infoDispatchSlice.actions;

export const selectInfoDispatchAnalysisList = (state) =>
  state.infoDispatch.analysisList;
export const selectInfoDispatchDatasetList = (state) =>
  state.infoDispatch.datasetList;
export const selectInfoDispatchGraphModelList = (state) =>
  state.infoDispatch.graphModelList;
export const selectInfoDispatchDatasetDetails = (state) =>
  state.infoDispatch.datasetDetails;
export const selectInfoDispatchColumnCount = (state) =>
  state.infoDispatch.columnCount;
export const selectInfoDispatchDWH = (state) => state.infoDispatch.dwh;
export const selectInfoDispatchRelevanceRanking = (state) =>
  state.infoDispatch.relevanceRanking;
export const selectInfoDispatchGeneratedDatasetDetails = (state) =>
  state.infoDispatch.generatedDatasetDetails;
export const selectInfoDispatchGeneratedModelDetails = (state) =>
  state.infoDispatch.generatedModelDetails;
export const selectInfoDispatchUserCountInModel = (state) =>
  state.infoDispatch.userCountInModel;
export const selectInfoDispatchColumnsUsers = (state) =>
  state.infoDispatch.columnsUsers;
export const selectInfoDispatchOutcomeColumns = (state) =>
  state.infoDispatch.outcomeColumns;
export const selectInfoDispatchRelevanceColumns = (state) =>
  state.infoDispatch.relevanceColumns;
export default infoDispatchSlice.reducer;
