import { useTranslation } from "react-i18next";

import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

import { useAuthentication } from "../../../context/auth";

interface ErrorDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  error: (error: any) => void;
}

export function ErrorDialog({ open, onOpenChange, error }: ErrorDialogProps) {
  const { logOut } = useAuthentication();
  const { t } = useTranslation();

  const handleLogin = () => {
    logOut();
    onOpenChange(false);
    error(null);
    window.location.reload();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        onInteractOutside={(e) => e.preventDefault()}
        onOpenAutoFocus={(e) => e.preventDefault()}
        hideCloseButton={true}
        className="max-w-[480px] justify-center border border-gray-100 dark:border-white/10 rounded-xl"
      >
        <div className="flex flex-col w-full px-10">
          <DialogHeader>
            <DialogTitle>{t("auth-session-title")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col w-full py-10">
            <p>{t("auth-session-message")}</p>
          </div>
          <div className="flex flex-row ml-auto">
            <Button
              type="button"
              variant="headless"
              size="headless"
              onClick={() => handleLogin()}
              className="flex flex-row h-10 w-[120px] gap-2 items-center justify-center rounded-lg text-xs font-medium text-white bg-[#0195c5] hover:bg-[#0195c5]/80"
            >
              {t("auth-session-submit")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
