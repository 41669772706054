import * as React from "react";

import { useLocation } from "react-router-dom";
import i18n from "i18next";

export const useLocale = () => {
  const [pathname, setPathname] = React.useState<string>("");
  const [locale, setLocale] = React.useState<string>("");

  const location = useLocation();

  React.useEffect(() => {
    const langCode = location.pathname.substring(1, 3);
    if (langCode === "en") {
      setLocale("/en");
      setPathname(location.pathname.substring(3));
      i18n.changeLanguage("en");
    } else {
      setLocale("");
      setPathname("/en" + location.pathname);
      i18n.changeLanguage("ja");
    }
  }, [location]);

  return { locale, pathname };
};

export const navLocale = (path: string) => {
  const currentLang = i18n.language;
  if (currentLang === "en") {
    return "/en" + path;
  } else {
    return path;
  }
};
