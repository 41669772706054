import { IdCodeDef } from "../_shared/type/id-code-def";
import { UpdateKpiReq } from "./type/kpi";

import { demographicRes } from "../../tests/dashboard/demographic";
import { rankingRes } from "../../tests/dashboard/ranking";
import { priorRes } from "../../tests/dashboard/prior";
import { trendRes } from "../../tests/dashboard/trend";
import { kpiRes } from "../../tests/dashboard/kpi";

// import { apiClient } from "../../libs/api";

export class DashboardGateway {
  async fetchDemographic(req: IdCodeDef): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/demographic`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = demographicRes;
    return res as any;
  }

  async fetchTrend(req: IdCodeDef): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/trend`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = trendRes;
    return res as any;
  }

  async fetchKpi(req: IdCodeDef): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/kpi`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = kpiRes;
    return res as any;
  }

  async updateKpi(req: UpdateKpiReq): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/kpi`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = true;
    return res as any;
  }

  async fetchRanking(req: IdCodeDef): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/ranking`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = rankingRes;
    return res as any;
  }

  async fetchPrior(req: IdCodeDef): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/prior`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = priorRes;
    return res as any;
  }
}
