import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { DataManagerGateway } from "./core/data-manager/gateway";
import { DataManagerUsecase } from "./core/data-manager/usecase";
import { DashboardGateway } from "./core/dashboard/gateway";
import { DashboardUsecase } from "./core/dashboard/usecase";
import { AccountGateway } from "./core/account/gateway";
import { AccountUsecase } from "./core/account/usecase";
import { SharedGateway } from "./core/_shared/gateway";
import { SharedUsecase } from "./core/_shared/usecase";
import { GraphGateway } from "./core/graph/gateway";
import { GraphUsecase } from "./core/graph/usecase";
import { AuthGateway } from "./core/auth/gateway";
import { AuthUsecase } from "./core/auth/usecase";

import { ErrorProvider, useError } from "./context/error";
import { AuthProvider } from "./context/auth";

type UseCases = {
  dataManagerUsecase: DataManagerUsecase;
  dashboardUsecase: DashboardUsecase;
  accountUsecase: AccountUsecase;
  sharedUsecase: SharedUsecase;
  graphUsecase: GraphUsecase;
  authUsecase: AuthUsecase;
};

const UsecaseContext = React.createContext<UseCases | null>(null);

export function UsecaseProvider({ children }: { children: React.ReactNode }) {
  const dataManagerRepo = new DataManagerGateway();
  const dashboardRepo = new DashboardGateway();
  const accountRepo = new AccountGateway();
  const sharedRepo = new SharedGateway();
  const graphRepo = new GraphGateway();
  const authRepo = new AuthGateway();
  const { error } = useError();

  const dataManagerUsecase = new DataManagerUsecase(dataManagerRepo, error);
  const dashboardUsecase = new DashboardUsecase(dashboardRepo, error);
  const accountUsecase = new AccountUsecase(accountRepo, error);
  const sharedUsecase = new SharedUsecase(sharedRepo, error);
  const graphUsecase = new GraphUsecase(graphRepo, error);
  const authUsecase = new AuthUsecase(authRepo, error);

  const usecases: UseCases = {
    dataManagerUsecase,
    dashboardUsecase,
    accountUsecase,
    sharedUsecase,
    graphUsecase,
    authUsecase,
  };

  return (
    <UsecaseContext.Provider value={usecases}>
      {children}
    </UsecaseContext.Provider>
  );
}

export function Provider({ children }: { children: React.ReactNode }) {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorProvider>
        <UsecaseProvider>
          <AuthProvider>{children}</AuthProvider>
        </UsecaseProvider>
      </ErrorProvider>
    </QueryClientProvider>
  );
}

export function useUsecases() {
  const context = React.useContext(UsecaseContext);
  if (!context) {
    throw new Error("useUsecases must be used within a Provider");
  }
  return context;
}
