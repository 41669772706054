import { LoginUser } from "../type/login-user";

import { initialState } from "../../../context/auth";
import { GB } from "../../../const/size";

export class LoginUserAdapter {
  static toOutputDTO({
    clientData,
    userData,
  }: {
    clientData: any;
    userData: any;
  }): LoginUser {
    if (!userData.data || userData.data.length === 0) {
      return initialState as LoginUser;
    }

    const data = userData.data.find(
      (user: any) => user.user_id === userData.user_id
    );

    return {
      id: data?.user_id ?? null,
      role: data?.status ?? 0,
      email: data?.email ?? null,
      client: {
        id: clientData.vendor_id ?? null,
        name: clientData.vendor_name ?? null,
        logo: clientData.image_url ?? null,
        plan: {
          active: clientData.enable_pu,
          fee_type: clientData.fee_type,
          app_linkage: !clientData.is_noapps,
          limit_size: clientData.is_bill
            ? clientData.fee_type === "capacity"
              ? clientData.bill_code_num * GB
              : clientData.bill_code_num
            : null,
          options: clientData.is_advance ? ["advance"] : [],
        },
        payment: {
          method: clientData.is_bill ? "bank" : "card",
        },
      },
      trial: {
        status: userData.trial_status ?? null,
        expire: userData.trial_expire_date
          ? new Date(userData.trial_expire_date * 1000)
          : 0,
      },
    } as LoginUser;
  }
}
