"use client";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import { Button } from "../../../components/ui/button";
import { navLocale } from "../../../libs/locale";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

interface LogoDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function LogoDialog({ open, onOpenChange }: LogoDialogProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, setCookie] = useCookies(["logo"]);

  const handleSkip = () => {
    const expire = new Date();
    expire.setDate(expire.getDate() + 7);
    setCookie("logo", expire, {
      expires: expire,
      path: "/",
      secure: true,
    });
    onOpenChange(false);
  };

  const handleUpload = () => {
    const expire = new Date();
    expire.setDate(expire.getDate() + 7);
    setCookie("logo", expire, {
      expires: expire,
      path: "/",
      secure: true,
    });
    onOpenChange(false);
    navigate(navLocale("/account"));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[480px] justify-center border border-gray-100 dark:border-white/10 rounded-xl">
        <div className="flex flex-col w-full px-10">
          <DialogHeader>
            <DialogTitle>{t("auth-logo-title")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col w-full py-10">
            <p>{t("auth-logo-message")}</p>
          </div>
          <div className="flex flex-row justify-between gap-20">
            <Button
              type="button"
              variant="headless"
              size="headless"
              onClick={() => handleSkip()}
              className="flex flex-row h-10 w-[120px] gap-2 items-center justify-center rounded-lg text-xs font-medium text-white bg-[#ef8262] hover:bg-[#ef8262]/80"
            >
              {t("auth-logo-submit-1")}
            </Button>
            <Button
              type="button"
              variant="headless"
              size="headless"
              onClick={() => handleUpload()}
              className="flex flex-row h-10 w-[120px] gap-2 items-center justify-center rounded-lg text-xs font-medium text-white bg-[#0195c5] hover:bg-[#0195c5]/80"
            >
              {t("auth-logo-submit-2")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
