import { Profile } from "../../account/type/profile";

export class ProfileAdapter {
  static toOutputDTO(res: any): Profile {
    const output_dto: Profile = {
      profile: {
        name: res.vendor_name,
        email: res.email,
        phone: res.phone,
      },
      owner: {
        department: res.manager_department,
        position: res.manager_position,
        name: res.manager_name,
      },
      address: {
        address: res.address,
        city: res.city,
        prefecture: res.prefecture,
        postcode: res.postcode,
        country: res.country,
      },
      logo: { url: res.image_url },
    };
    return output_dto;
  }
}
