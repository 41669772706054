const account = {
  // ACCOUNT
  "account-title": "アカウント",
  "account-discription":
    "このページでは、組織に関する契約やメンバーなどを管理することができます。",

  // PROFILE
  "account-profile-tab": "プロフィール",
  "account-profile-title": "プロフィール",
  "account-profile-field-1": "組織名",
  "account-profile-field-2": "メールアドレス",
  "account-profile-field-3": "電話番号",
  "account-profile-owner-title": "オーナー",
  "account-profile-owner-field-1": "部署名",
  "account-profile-owner-field-2": "役職",
  "account-profile-address-title": "アドレス",
  "account-profile-address-field-1": "住所",
  "account-profile-address-field-2": "市区町村",
  "account-profile-address-field-3": "都道府県",
  "account-profile-address-field-4": "郵便番号",
  "account-profile-address-field-5": "国名",
  "account-profile-logo-title": "プロフィールロゴ",
  "account-profile-logo-note":
    "Note: アップロードされたプロフィールロゴは、利用規約に従って管理・使用されます。",
  "account-profile-field-error-1": "このフィールドは入力が必須です。",
  "account-profile-field-error-2": "有効なメールアドレスを入力してください。",
  "account-profile-field-error-3": "有効な電話番号を入力してください。",
  "account-profile-field-error-4": "有効な郵便番号を入力してください。",
  "account-profile-field-error-5": "有効な国コードを入力してください。",
  "account-profile-field-error-6": "有効なURLを入力してください。",
  "account-profile-field-error-7":
    "有効なロゴデータをアップロードしてください。(PNG, JPEG, WebP)",
  "account-profile-submit-1": "更新する",
  "account-profile-submit-2": "アップロード",
  "account-profile-toast-success": "正常に更新されました。",
  "account-profile-toast-error": "更新に失敗しました。",

  // MEMBER
  "account-member-tab": "メンバー",
  "account-member-title": "メンバー",
  "account-member-discription": "メンバーに関する管理、編集ができます。",
  "account-member-toolbar": "メンバー追加",
  "account-member-table-column-1": "名前",
  "account-member-table-column-2": "メールアドレス",
  "account-member-table-column-3": "最終ログイン",
  "account-member-table-column-4": "最終アップデート",
  "account-member-table-column-5": "権限",
  "account-member-table-action-1": "編集",
  "account-member-table-action-2": "削除",
  "account-member-table-data-1": "メンバー",
  "account-member-table-data-2": "管理者",
  "account-member-modal-add-title": "メンバー追加",
  "account-member-modal-add-discription":
    "完了したら更新をクリックしてください。",
  "account-member-modal-add-field-error-1": "このフィールドは入力が必須です。",
  "account-member-modal-add-field-error-2":
    "有効なメールアドレスを入力してください。",
  "account-member-modal-add-field-error-3":
    "有効なパスワードを入力してください。(大文字、小文字、数字、特殊文字を含み、8文字以上)。",
  "account-member-modal-add-filed-1": "名前",
  "account-member-modal-add-filed-2": "メールアドレス",
  "account-member-modal-add-filed-3": "パスワード",
  "account-member-modal-add-filed-4": "権限",
  "account-member-modal-add-filed-4-option-1": "メンバー",
  "account-member-modal-add-filed-4-option-2": "管理者",
  "account-member-modal-add-submit": "追加する",
  "account-member-modal-add-toast-success": "正常に更新されました。",
  "account-member-modal-add-toast-error": "更新に失敗しました。",
  "account-member-modal-edit-title": "メンバー編集",
  "account-member-modal-edit-discription":
    "完了したら更新をクリックしてください。",
  "account-member-modal-edit-field-error-1": "このフィールドは入力が必須です。",
  "account-member-modal-edit-filed-1": "名前",
  "account-member-modal-edit-filed-2": "権限",
  "account-member-modal-edit-filed-2-option-1": "メンバー",
  "account-member-modal-edit-filed-2-option-2": "管理者",
  "account-member-modal-edit-submit": "更新する",
  "account-member-modal-edit-toast-success": "正常に更新されました。",
  "account-member-modal-edit-toast-error": "更新に失敗しました。",
  "account-member-modal-self-edit-title": "メンバー編集",
  "account-member-modal-self-edit-discription":
    "完了したら更新をクリックしてください。",
  "account-member-modal-self-edit-field-error-1":
    "このフィールドは入力が必須です。",
  "account-member-modal-self-edit-field-error-2":
    "有効なパスワードを入力してください。(大文字、小文字、数字、特殊文字を含み、8文字以上)。",
  "account-member-modal-self-edit-filed-1": "名前",
  "account-member-modal-self-edit-filed-2": "古いパスワード",
  "account-member-modal-self-edit-filed-3": "新しいパスワード",
  "account-member-modal-self-edit-submit": "更新する",
  "account-member-modal-self-edit-toast-success": "正常に更新されました。",
  "account-member-modal-self-edit-toast-error": "更新に失敗しました。",
  "account-member-modal-delete-title": "メンバー削除",
  "account-member-modal-delete-discription":
    "完了したら削除をクリックしてください。",
  "account-member-modal-delete-field-error-1":
    "このフィールドは入力が必須です。",
  "account-member-modal-delete-field-error-2":
    "有効なメールアドレスを入力してください。",
  "account-member-modal-delete-filed-1": "名前",
  "account-member-modal-delete-filed-2": "メールアドレス",
  "account-member-modal-delete-submit": "削除する",
  "account-member-modal-delete-toast-success": "正常に更新されました。",
  "account-member-modal-delete-toast-error": "更新に失敗しました。",

  // BILLING
  "account-billing-tab": "契約情報",
  "account-billing-section-1-title": "契約中プラン",
  "account-billing-section-1-discription":
    "組織に関する契約プランを確認することができます。",
  "account-billing-section-2-title": "プラン変更",
  "account-billing-section-2-discription":
    "組織に関する契約プランを変更することができます。",

  // INVOICE
  "account-invoice-tab": "支払い情報",
  "account-invoice-title": "領収書",
  "account-invoice-discription": "組織に関するお支払い情報の確認ができます。",
};

export default account;
