export const demographicReq = {
  x_attr_id: 1, // age
  y_attr_id: 1, // age
};

export const demographicRes = [
  { attr: 1, 1: 1234, 2: 1234 },
  { label: 2, 1: 1234, 2: 1234 },
  { label: 3, 1: 1234, 2: 1234 },
];
