import { DeleteIDCodeReq, EditIDCodeReq, IDCode } from "../type/id-code";
import { APIStatus } from "../../_shared/type/status";

export class IDCodeAdapter {
  static toEditIDCodeInputDTO(req: EditIDCodeReq): any {
    const input_dto = {
      category_id: req.group.id,
      user_code: req.id_code,
    };
    return input_dto;
  }

  static toDeleteIDCodeInputDTO(req: DeleteIDCodeReq): any {
    const input_dto = {
      user_code: req.id_code,
    };
    return input_dto;
  }

  static toIDCodeOutputDTO(res: any): IDCode[] {
    const output_dto: IDCode[] = res.service
      ? res.service.map((e: any) => ({
          id: e.id,
          id_code: e.id_code,
          status: e.is_code_status,
          integration_code: e.youd_user_id ?? null,
          group: {
            id: e.subject_group.id ?? null,
            name: e.subject_group.name ?? null,
          },
          description: e.memo ?? null,
        }))
      : [];
    return output_dto;
  }

  static toIDCodeStatus(res: any): APIStatus {
    const output_dto: APIStatus = {
      status: res.status,
      data: res.data,
    };
    return output_dto;
  }
}
