import { apiClient } from "../../libs/api";

export class GraphGateway {
  async fetchOutcome(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/node/outcome`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }

  async fetchRelevance(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/node/relevance`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }
}
