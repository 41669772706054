export const idCodeReq: any = {
  filters: [
    {
      id: 1,
      attrs: [
        { id: 1, value: true },
        { id: 2, value: true },
        { id: 3, value: false },
      ],
    },
    {
      id: 2,
      attrs: [
        { id: 1, value: true },
        { id: 2, value: false },
        { id: 3, value: false },
      ],
    },
  ],
  range: {
    start: new Date(1995, 11, 17),
    end: new Date(2022, 11, 17),
  },
  id_code_group: [
    { id: 1, value: true },
    { id: 2, value: false },
  ],
};

export const idCodeDef: any = [
  "id_code_1",
  "id_code_2",
  "id_code_3",
  "id_code_4",
  "id_code_5",
];
