import { Invoice } from "../../account/type/invoice";

export class InvoiceAdapter {
  static toOutputDTO(res: any): Invoice[] {
    const output_dto: Invoice[] = res.data.data
      ? res.data.data.map((e: any) => ({
          id: e.number,
          date: e.created,
          price: e.total,
          stripe: e.invoice_url,
        }))
      : [];
    return output_dto;
  }
}
