import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAnalysisFormForNewModel: false,
  showAnalysisForm: false,
  showDatasetForm: false,
  showModelForm: false,
  showConfirm: false,
  showGraphConfig: true,
  showReport: false,
  showLoading: false,
  nodeTimeLagsDisplay: {},
  nodeTableSortModel: [],
};

export const uiDispatchSlice = createSlice({
  name: "uiDispatch",
  initialState,
  reducers: {
    setUIDispatchShowLoading: (state, action) => {
      state.showLoading = action.payload;
    },
    setUIDispatchNodeTimeLagsDisplay: (state, action) => {
      state.nodeTimeLagsDisplay = action.payload;
    },
    setUIDispatchNodeTableSortModel: (state, action) => {
      state.nodeTableSortModel = action.payload;
    },
    setUIDispatchShowReport: (state, action) => {
      state.showReport = action.payload;
    },
    toggleUIDispatchShowReport: (state, action) => {
      state.showReport = !state.showReport;
    },
    toggleUIDispatchShowModelForm: (state, action) => {
      state.showModelForm = !state.showModelForm;
    },
    toggleUIDispatchShowGraphConfig: (state, action) => {
      state.showGraphConfig = !state.showGraphConfig;
    },
    toggleUIDispatchShowConfirm: (state, action) => {
      state.showConfirm = !state.showConfirm;
    },
    toggleUIDispatchShowAnalysisForm: (state, action) => {
      state.showAnalysisForm = !state.showAnalysisForm;
    },
    toggleUIDispatchShowAnalysisForNewModel: (state, action) => {
      state.showAnalysisFormForNewModel = !state.showAnalysisFormForNewModel;
    },
    toggleUIDispatchShowDatasetForm: (state, action) => {
      state.showDatasetForm = !state.showDatasetForm;
    },
  },
});

export const {
  setUIDispatchShowLoading,
  setUIDispatchNodeTimeLagsDisplay,
  setUIDispatchNodeTableSortModel,
  setUIDispatchShowReport,
  toggleUIDispatchShowReport,
  toggleUIDispatchShowModelForm,
  toggleUIDispatchShowGraphConfig,
  toggleUIDispatchShowConfirm,
  toggleUIDispatchShowAnalysisForm,
  toggleUIDispatchShowAnalysisForNewModel,
  toggleUIDispatchShowDatasetForm,
} = uiDispatchSlice.actions;

export const selectUIDispatchShowLoading = (state) =>
  state.uiDispatch.showLoading;
export const selectUIDispatchNodeTimeLagsDisplay = (state) =>
  state.uiDispatch.nodeTimeLagsDisplay;
export const selectUIDispatchNodeTableSortModel = (state) =>
  state.uiDispatch.nodeTableSortModel;
export const selectUIDispatchShowReport = (state) =>
  state.uiDispatch.showReport;
export const selectUIDispatchShowModelForm = (state) =>
  state.uiDispatch.showModelForm;
export const selectUIDispatchShowGraphConfig = (state) =>
  state.uiDispatch.showGraphConfig;
export const selectUIDispatchShowConfirm = (state) =>
  state.uiDispatch.showConfirm;
export const selectUIDispatchShowAnalysisForm = (state) =>
  state.uiDispatch.showAnalysisForm;
export const selectUIDispatchShowAnalysisForNewModel = (state) =>
  state.uiDispatch.showAnalysisFormForNewModel;
export const selectUIDispatchShowDatasetForm = (state) =>
  state.uiDispatch.showDatasetForm;

export default uiDispatchSlice.reducer;
