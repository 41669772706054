const account = {
  // ACCOUNT
  "account-title": "Account",
  "account-discription":
    "On this page, you can manage contracts, members, etc. related to your organization.",

  // PROFILE
  "account-profile-tab": "Profile",
  "account-profile-title": "Profile",
  "account-profile-field-1": "Organization name",
  "account-profile-field-2": "Email",
  "account-profile-field-3": "Phone Number",
  "account-profile-owner-title": "Owner",
  "account-profile-owner-field-1": "Department",
  "account-profile-owner-field-2": "Position",
  "account-profile-address-title": "Address",
  "account-profile-address-field-1": "Address",
  "account-profile-address-field-2": "City",
  "account-profile-address-field-3": "Prefecture",
  "account-profile-address-field-4": "Postcode",
  "account-profile-address-field-5": "Country",
  "account-profile-logo-title": "Profile logo",
  "account-profile-logo-note":
    "Note: Uploaded Profile logos will be managed and used in accordance with the Terms of Use.",
  "account-profile-field-error-1": "This field is required.",
  "account-profile-field-error-2": "Please enter a valid email address.",
  "account-profile-field-error-3": "Please enter a valid phone number.",
  "account-profile-field-error-4": "Please enter a valid postcode.",
  "account-profile-field-error-5": "Please enter a valid country code.",
  "account-profile-field-error-6": "Please enter a valid url.",
  "account-profile-field-error-7":
    "Please upload valid logo data (PNG, JPEG, WebP).",
  "account-profile-submit-1": "Update profile",
  "account-profile-submit-2": "Upload new picture",
  "account-profile-toast-success": "Updated successfully.",
  "account-profile-toast-error": "Update failed.",

  // MEMBER
  "account-member-tab": "Member",
  "account-member-title": "Member",
  "account-member-discription":
    "This page allows you to manage and edit member information.",
  "account-member-toolbar": "Add Member",
  "account-member-table-column-1": "Name",
  "account-member-table-column-2": "Email",
  "account-member-table-column-3": "Login at",
  "account-member-table-column-4": "Updated at",
  "account-member-table-column-5": "Role",
  "account-member-table-action-1": "Edit",
  "account-member-table-action-2": "Delete",
  "account-member-table-data-1": "Member",
  "account-member-table-data-2": "Admin",
  "account-member-modal-add-title": "Add Member",
  "account-member-modal-add-discription": "Click update when you are done.",
  "account-member-modal-add-field-error-1": "This field is required.",
  "account-member-modal-add-field-error-2":
    "Please enter a valid email address.",
  "account-member-modal-add-field-error-3":
    "Please enter a valid password. (At least 8 characters, including upper and lower case letters, numbers, and special characters).",
  "account-member-modal-add-filed-1": "Name",
  "account-member-modal-add-filed-2": "Email",
  "account-member-modal-add-filed-3": "Password",
  "account-member-modal-add-filed-4": "Roles",
  "account-member-modal-add-filed-4-option-1": "Member",
  "account-member-modal-add-filed-4-option-2": "Admin",
  "account-member-modal-add-submit": "Add member",
  "account-member-modal-add-toast-success": "Updated successfully.",
  "account-member-modal-add-toast-error": "Update failed.",
  "account-member-modal-edit-title": "Edit Member",
  "account-member-modal-edit-discription": "Click update when you are done.",
  "account-member-modal-edit-field-error-1": "This field is required.",
  "account-member-modal-edit-filed-1": "Name",
  "account-member-modal-edit-filed-2": "Roles",
  "account-member-modal-edit-filed-2-option-1": "Member",
  "account-member-modal-edit-filed-2-option-2": "Admin",
  "account-member-modal-edit-submit": "Save Change",
  "account-member-modal-edit-toast-success": "Updated successfully.",
  "account-member-modal-edit-toast-error": "Update failed.",
  "account-member-modal-self-edit-title": "Edit Member",
  "account-member-modal-self-edit-discription":
    "Click update when you are done.",
  "account-member-modal-self-edit-field-error-1": "This field is required.",
  "account-member-modal-self-edit-field-error-2":
    "Please enter a valid password. (At least 8 characters, including upper and lower case letters, numbers, and special characters).",
  "account-member-modal-self-edit-filed-1": "Name",
  "account-member-modal-self-edit-filed-2": "Old Password",
  "account-member-modal-self-edit-filed-3": "New Password",
  "account-member-modal-self-edit-submit": "Save Change",
  "account-member-modal-self-edit-toast-success": "Updated successfully.",
  "account-member-modal-self-edit-toast-error": "Update failed.",
  "account-member-modal-delete-title": "Delete Member",
  "account-member-modal-delete-discription": "Click update when you are done.",
  "account-member-modal-delete-field-error-1": "This field is required.",
  "account-member-modal-delete-field-error-2":
    "Please enter a valid email address.",
  "account-member-modal-delete-filed-1": "Name",
  "account-member-modal-delete-filed-2": "Email",
  "account-member-modal-delete-submit": "Delete member",
  "account-member-modal-delete-toast-success": "Updated successfully.",
  "account-member-modal-delete-toast-error": "Update failed.",

  // BILLING
  "account-billing-tab": "Billing & Plan",
  "account-billing-section-1-title": "Current Plan",
  "account-billing-section-1-discription":
    "This page allows you to manage contracts, members, etc. related to your organization.",
  "account-billing-section-2-title": "Edit Plan",
  "account-billing-section-2-discription":
    "This page allows you to manage contracts, members, etc. related to your organization.",

  // INVOICE
  "account-invoice-tab": "Invoice",
  "account-invoice-title": "Invoice",
  "account-invoice-discription":
    "This page allows you to manage payment information and invoice, etc. related to your organization.",
};

export default account;
