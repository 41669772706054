import { IGraphRepository } from "./irepo";

export class GraphUsecase {
  private repository: IGraphRepository;
  private error: (error: any) => void;

  constructor(repository: IGraphRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  // INFO
  async fetchOutcome(): Promise<any> {
    try {
      return await this.repository.fetchOutcome();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
