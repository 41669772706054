import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analysis: {},
  dataset: {},
  model: {},
  datasetVar: "",
  numberOfNodes: 0,
  objVar: "",
  expVars: [],
  newModelName: "",
  dataGranularity: 33,
  useSmallytics: false,
  nodeTimeLags: {},
};

export const userDispatchSlice = createSlice({
  name: "userDispatch",
  initialState,
  reducers: {
    setUserDispatchAnalysis: (state, action) => {
      state.analysis = action.payload;
    },
    setUserDispatchDataset: (state, action) => {
      state.dataset = action.payload;
    },
    setUserDispatchModel: (state, action) => {
      state.model = action.payload;
    },
    setUserDispatchDatasetVar: (state, action) => {
      state.datasetVar = action.payload;
    },
    setUserDispatchNumberOfNodes: (state, action) => {
      state.numberOfNodes = action.payload;
    },
    setUserDispatchObjVar: (state, action) => {
      state.objVar = action.payload;
    },
    setUserDispatchExpVars: (state, action) => {
      state.expVars = action.payload;
    },
    setUserDispatchNewModelName: (state, action) => {
      state.newModelName = action.payload;
    },
    setUserDispatchDataGranularity: (state, action) => {
      state.dataGranularity = action.payload;
    },
    setUserDispatchUseSmallytics: (state, action) => {
      state.useSmallytics = action.payload;
    },
    setUserDispatchNodeTimeLags: (state, action) => {
      state.nodeTimeLags = action.payload;
    },
    toggleUserDispatchUseSmallytics: (state, action) => {
      state.useSmallytics = !state.useSmallytics;
    },
  },
});

export const {
  setUserDispatchAnalysis,
  setUserDispatchDataset,
  setUserDispatchModel,
  setUserDispatchDatasetVar,
  setUserDispatchNumberOfNodes,
  setUserDispatchObjVar,
  setUserDispatchExpVars,
  setUserDispatchNewModelName,
  setUserDispatchDataGranularity,
  setUserDispatchUseSmallytics,
  setUserDispatchNodeTimeLags,
  toggleUserDispatchUseSmallytics,
} = userDispatchSlice.actions;

export const selectUserDispatchAnalysis = (state) =>
  state.userDispatch.analysis;
export const selectUserDispatchDataset = (state) => state.userDispatch.dataset;
export const selectUserDispatchModel = (state) => state.userDispatch.model;
export const selectUserDispatchDatasetVar = (state) =>
  state.userDispatch.datasetVar;
export const selectUserDispatchNumberOfNodes = (state) =>
  state.userDispatch.numberOfNodes;
export const selectUserDispatchObjVar = (state) => state.userDispatch.objVar;
export const selectUserDispatchExpVars = (state) => state.userDispatch.expVars;
export const selectUserDispatchNewModelName = (state) =>
  state.userDispatch.newModelName;
export const selectUserDispatchDataGranularity = (state) =>
  state.userDispatch.dataGranularity;
export const selectUserDispatchUseSmallytics = (state) =>
  state.userDispatch.useSmallytics;
export const selectUserDispatchNodeTimeLags = (state) =>
  state.userDispatch.nodeTimeLags;
export default userDispatchSlice.reducer;
