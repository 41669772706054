import { IdCodeDef } from "../_shared/type/id-code-def";
import { DemographicDef } from "./type/demographic";
import { KpiDef, UpdateKpiReq } from "./type/kpi";
import { RankingDef } from "./type/ranking";
import { PriorDef } from "./type/prior";
import { TrendDef } from "./type/trend";

import { IDashboardRepository } from "./irepo";

export class DashboardUsecase {
  private repository: IDashboardRepository;
  private error: (error: any) => void;

  constructor(repository: IDashboardRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  async fetchDemographic(req: IdCodeDef): Promise<DemographicDef> {
    try {
      return await this.repository.fetchDemographic(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async fetchTrend(req: IdCodeDef): Promise<TrendDef> {
    try {
      return await this.repository.fetchTrend(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async fetchKpi(req: IdCodeDef): Promise<KpiDef> {
    try {
      return await this.repository.fetchKpi(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async updateKpi(req: UpdateKpiReq): Promise<boolean> {
    try {
      return await this.repository.updateKpi(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async fetchRanking(req: IdCodeDef): Promise<RankingDef> {
    try {
      return await this.repository.fetchRanking(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async fetchPrior(req: IdCodeDef): Promise<PriorDef> {
    try {
      return await this.repository.fetchPrior(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
