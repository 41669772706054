import { KpiDef } from "../../core/dashboard/type/kpi";

export const kpiReq = null;

export const kpiRes: KpiDef = [
  {
    id: 1,
    key: "setCount",
    value: 12345,
    children: [
      { id: 1, value: 0.3 },
      { id: 1, value: 0.6 },
      { id: 1, value: 3 },
      { id: 1, value: 2 },
      { id: 2, value: 1 },
    ],
  },
  {
    id: 2,
    key: "condition",
    value: 12345,
    children: [
      { id: 1, value: 1240 },
      { id: 2, value: 3000 },
    ],
  },
  {
    id: 3,
    key: "bodyTemp",
    value: 12345,
    children: [
      { id: 1, value: 26 },
      { id: 2, value: 36.4 },
      { id: 3, value: 36.4 },
      { id: 4, value: 40 },
    ],
  },
  {
    id: 4,
    key: "weather",
    value: 12345,
    children: [
      { id: 1, value: 0 },
      { id: 2, value: 0.2 },
      { id: 2, value: 3 },
      { id: 2, value: 3 },
    ],
  },
  {
    id: 5,
    key: "weather",
    value: 12345,
    children: [
      { id: 1, value: 12345 },
      { id: 2, value: 12345 },
    ],
  },
];
