import { BrowserType } from "../types/browser";
import { OSType } from "../types/os";
import { cn } from "./utils";

/**
 * Compresses an array to a new array containing the first element multiplied by the step and
 * the last element multiplied by the step. If the input array is empty, returns an empty array.
 * @param {number[]} array - The array to be compressed.
 * @param {number} step - The multiplier for the first and last elements.
 * @returns {number[]} A new array containing the first and last elements of the input array, each multiplied by the step.
 */
export const compressArray = (array: number[], step: number): number[] => {
  if (array.length === 0) return [];
  return [array[0] * step, array[array.length - 1] * step];
};

/**
 * Expands a two-element array into a full range of numbers. The range is determined by dividing
 * each element by the step and then creating a range from the resulting start and end values.
 * Throws an error if the input array does not have exactly two elements.
 * @param {number[]} array - A two-element array representing the start and end of the compressed range.
 * @param {number} step - The divisor used to expand the range.
 * @returns {number[]} An array of numbers representing the expanded range.
 */
export const expandArray = (array: number[], step: number): number[] => {
  if (array.length !== 2) {
    throw new Error("Array must have exactly two elements");
  }

  const [startCompressed, endCompressed] = array;
  const start = startCompressed / step;
  const end = endCompressed / step;
  const expandedArray: number[] = [];

  for (let i = start; i <= end; i++) {
    expandedArray.push(i);
  }

  return expandedArray;
};

/**
 * This function takes a date in string or Date object format and
 * converts it to a formatted string in a specified format.
 * Currently supports format ("YYYY/MM/DD") and other formats.
 * If the input date is invalid, it returns an empty string.
 * @param {string | Date} date - The date to format, either as a string or Date object.
 * @param {string} formatType - The type of format to apply. Default is "YYYY/MM/DD".
 * @returns {string} The formatted date in the specified format.
 */
export const formatDate = (
  date: string | Date,
  formatType:
    | "YYYY-MM-DD"
    | "YYYY/MM/DD"
    | "YYYY-MM-DD-HH-MM-SS"
    | "YYYY/MM/DD HH:MM:SS"
    | "DD/MM/YYYY" = "YYYY/MM/DD"
): string => {
  let parsedDate: Date;

  if (date == null) {
    return "";
  }

  if (typeof date === "string") {
    parsedDate = new Date(date);
  } else {
    parsedDate = date;
  }

  if (isNaN(parsedDate.getTime())) {
    return "";
  }

  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const hours = String(parsedDate.getHours()).padStart(2, "0");
  const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
  const seconds = String(parsedDate.getSeconds()).padStart(2, "0");

  switch (formatType) {
    case "YYYY-MM-DD":
      return `${year}-${month}-${day}`;
    case "YYYY/MM/DD":
      return `${year}/${month}/${day}`;
    case "YYYY-MM-DD-HH-MM-SS":
      return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
    case "YYYY/MM/DD HH:MM:SS":
      return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    case "DD/MM/YYYY":
      return `${day}/${month}/${year}`;
    default:
      return `${year}/${month}/${day}`;
  }
};

/**
 * Converts a given Unix timestamp to a formatted date string in the format "YYYY/MM/DD HH:mm".
 * @param unixTimestamp - The Unix timestamp to convert.
 * @returns A formatted date string in the "YYYY/MM/DD HH:mm" format.
 */
export const formatUnixTime = (date: string): string => {
  const timestamp =
    date.includes("-") || date.includes(":")
      ? new Date(date).getTime()
      : parseInt(date, 10) * 1000;
  const time = new Date(timestamp);

  const year = time.getFullYear();
  const month = String(time.getMonth() + 1).padStart(2, "0");
  const day = String(time.getDate()).padStart(2, "0");
  const hours = String(time.getHours()).padStart(2, "0");
  const minutes = String(time.getMinutes()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

/**
 * Converts a number into a string formatted with commas for readability,
 * according to the specified locale.
 * @param {number} amount - The number to be formatted.
 * @param {string} locale - The locale to use for formatting.
 * @return {string} A string representing the formatted number.
 */
export const formatAmount = (
  amount: number,
  locale: string = "en-US"
): string => {
  return amount.toLocaleString(locale);
};

/**
 * This function takes a file size in bytes and converts it into a human-readable
 * format, choosing the most appropriate unit (Bytes, KB, MB, GB, TB, PB) based on the size.
 * It returns an object with separate values for the size and unit.
 * @param {number} size - The file size in bytes.
 * @return {object} An object containing the formatted file size and the unit.
 */
export const formatFileSize = (
  size: number
): { value: number; unit: string } => {
  if (size < 1024) {
    return { value: size, unit: "Bytes" };
  } else if (size < 1024 * 1024) {
    return { value: Math.floor(size / 1024), unit: "KB" };
  } else if (size < 1024 * 1024 * 1024) {
    return { value: Math.floor(size / 1024 / 1024), unit: "MB" };
  } else if (size < 1024 * 1024 * 1024 * 1024) {
    return { value: Math.floor(size / 1024 / 1024 / 1024), unit: "GB" };
  } else if (size < 1024 * 1024 * 1024 * 1024 * 1024) {
    return { value: Math.floor(size / 1024 / 1024 / 1024 / 1024), unit: "TB" };
  } else {
    return {
      value: Math.floor(size / 1024 / 1024 / 1024 / 1024 / 1024),
      unit: "PB",
    };
  }
};

/**
 * Generates an array of tick values for a given range of numbers.
 * The function rounds the input values, calculates the range with margins,
 * and then creates evenly distributed ticks within this range.
 * @param {number[]} values - The array of numbers for which to generate tick values.
 * @param {number} num_ticks - The desired number of ticks (default is 5).
 * @param {number} margin_percent - The margin percentage to apply on each side of the range (default is 0.1).
 * @param {boolean} enable_minus - Flag to allow negative tick values (default is false).
 * @returns {number[]} An array of numbers representing the tick values.
 */
export const generateGraphTicks = (
  values: number[],
  num_ticks: number = 5,
  margin_percent: number = 0.1,
  enable_minus: boolean = false
): number[] => {
  // Rounded values array
  const roundedValue = values.map((num) => Math.round(num * 10) / 10);

  // Find the minimum and maximum values in the values array.
  const { min, max } = roundedValue.reduce(
    (acc, value) => ({
      min: Math.min(acc.min, value),
      max: Math.max(acc.max, value),
    }),
    { min: roundedValue[0], max: roundedValue[0] }
  );

  // Calculate the range and margins for the ticks.
  const range = max - min;
  const margin = range * margin_percent;
  let extendedMin = min - margin;
  const extendedMax = max + margin;

  // Determine the step for the tick values.
  let step;
  const base = Math.floor(Math.log10(range / num_ticks));
  const approxStep = range / (num_ticks * 10 ** base);

  if (approxStep <= 2) {
    step = 2 * 10 ** base;
  } else if (approxStep <= 5) {
    step = 5 * 10 ** base;
  } else {
    step = 10 ** (base + 1);
  }

  // Ensure step is a multiple of 0.1
  step = Math.ceil(step * 10) / 10;

  // Calculate the minimum and maximum tick values.
  let tickMin;
  if (!enable_minus && extendedMin < 0) {
    tickMin = 0;
  } else {
    tickMin = Math.floor(extendedMin / step) * step;
  }
  const tickMax = Math.ceil(extendedMax / step) * step;

  // Ensure newStep is a multiple of 0.1
  const newStep = Math.round(((tickMax - tickMin) / (num_ticks - 1)) * 10) / 10;

  // Generate the tick values and return the array.
  const ticks = [];
  for (let i = 0; i < num_ticks; i++) {
    ticks.push(Math.round((tickMin + newStep * i) * 10) / 10);
  }

  return ticks;
};

/**
 * Creates an anchor element that opens the user's default mail client to send an email.
 * The function encodes the email subject and body to ensure they are properly formatted
 * for a mailto link.
 * @param {string} email - The recipient's email address.
 * @param {string} subject - The subject of the email.
 * @param {string} body - The body text of the email.
 * @returns {JSX.Element} An anchor element styled as a button, which on click opens the mail client.
 */
export const SendEmail = ({
  title,
  email,
  className,
}: {
  title: string;
  email: {
    address: string;
    subject: string;
    body: string;
  };
  className?: string;
}) => {
  const encodedSubject = encodeURIComponent(email.subject);
  const encodedBody = encodeURIComponent(email.body);
  const mailto = `mailto:${encodeURIComponent(
    email.address
  )}?subject=${encodedSubject}&body=${encodedBody}`;

  return (
    <a
      href={mailto}
      className={cn(
        "flex items-center justify-center rounded-lg text-sm font-base h-8 px-3 bg-[#ef8262] text-white",
        className
      )}
    >
      {title}
    </a>
  );
};

/**
 * Detects the operating system of the user's device.
 * @returns {OSType} - The name of the operating system ('macOS', 'iOS', 'Windows', 'Android', 'Linux', or 'Unknown').
 */
export const detectOS = (): OSType => {
  const userAgent = window.navigator.userAgent;
  let os: OSType = "Unknown";

  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    os = "macOS";
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    os = "iOS";
  } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(userAgent)) {
    os = "Linux";
  }

  return os;
};

/**
 * Detects the browser of the user's device.
 * @returns {BrowserType} - The name of the browser ('Chrome', 'Firefox', 'Safari', 'Edge', 'Opera', 'Internet Explorer', or 'Unknown').
 */
export const detectBrowser = (): BrowserType => {
  const userAgent = window.navigator.userAgent;
  let browser: BrowserType = "Unknown";

  if (
    /Chrome/.test(userAgent) &&
    !/Edge/.test(userAgent) &&
    !/OPR/.test(userAgent)
  ) {
    browser = "Chrome";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Firefox";
  } else if (
    /Safari/.test(userAgent) &&
    !/Chrome/.test(userAgent) &&
    !/Edge/.test(userAgent) &&
    !/OPR/.test(userAgent)
  ) {
    browser = "Safari";
  } else if (/Edge/.test(userAgent)) {
    browser = "Edge";
  } else if (/OPR/.test(userAgent) || /Opera/.test(userAgent)) {
    browser = "Opera";
  } else if (/MSIE/.test(userAgent) || /Trident/.test(userAgent)) {
    browser = "Internet Explorer";
  }

  return browser;
};
