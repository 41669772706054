const auth = {
  "auth-session-title": "Your session has expired",
  "auth-session-message": "Please login again to continue using the service.",
  "auth-session-submit": "Login",

  "auth-login-title": "Login",
  "auth-login-message":
    "Your email address has been confirmed. \nPlease log in and set up two-factor authentication.",
  "auth-login-email": "Email",
  "auth-login-password": "Password",
  "auth-login-terms-detail": "I accept the terms of use",
  "auth-login-submit": "Login",
  "auth-login-forget": "Click here if you cannot login",
  "auth-login-setup": "Click here if you don't have an account",

  "auth-login-email-validate-error": "Check your email address.",
  "auth-login-password-validate-error": "Check your password.",
  "auth-login-api-error": "Please check your email or password.",

  "auth-setup-title": "Setup Account",
  "auth-setup-email": "Email",
  "auth-setup-password": "Password",
  "auth-setup-terms": "I accept the terms of use",
  "auth-setup-submit": "Submit",
  "auth-setup-complete-title": "Setup Account",
  "auth-setup-complete-message":
    "Confirmation email has been sent to your email address. \nPlease check your email.",

  "auth-setup-complete-api-error": "Internal Server Error.",

  "auth-setup-email-validate-error": "Check your email address.",
  "auth-setup-password-validate-error": "Check your password.",
  "auth-setup-terms-validate-error": "Check your terms agreement.",
  "auth-setup-terms-api-error": "You are already a registered user.",

  "auth-forget-title": "Reset Password",
  "auth-forget-code": "Reset Confirmation Code",
  "auth-forget-email": "Email",
  "auth-forget-password": "Password",
  "auth-forget-submit": "Submit",
  "auth-forget-back": "Back",
  "auth-forget-complete-message":
    "Your password has been reset. \nPlease use the link below to login using your new password.",
  "auth-forget-complete-submit": "Login",

  "auth-forget-code-validate-error": "Check your confirmation code.",
  "auth-forget-email-validate-error": "Check your email address.",
  "auth-forget-password-validate-error": "Check your password.",
  "auth-forget-api-user-error": "User is not registered.",
  "auth-forget-api-code-error":
    "Please enter a valid authorization code and password.",

  "auth-mfa-title": "Two-Factor Authentication",
  "auth-mfa-message-1":
    "xCausal uses an authentication app such as [Google Authenticator] to perform two-step authentication. \nPlease download in advance from the app store.",
  "auth-mfa-message-2":
    "If you would like to re-register for two-factor authentication, please contact us using the inquiry form below.",
  "auth-mfa-message-3":
    "Enter the 6-digit code displayed on your authentication app.",
  "auth-mfa-code": "Authentication Code",
  "auth-mfa-submit": "Send",
  "auth-mfa-back": "Back",

  "auth-mfa-code-validate-error": "Check your authentication code.",
  "auth-mfa-api-code-error": "Please enter a valid authorization code.",

  "auth-mfa-terms-title": "Terms of Use",
  "auth-mfa-terms-message":
    "The Terms of Use have been updated. \nPlease click on the link below to review and agree to the content.",
  "auth-mfa-terms-detail": "I accept the terms of use",
  "auth-mfa-terms-submit": "Login",

  "auth-mfa-terms-api-error": "Internal Server Error.",

  "auth-trial-title": "Trial Period",
  "auth-trial-message-1": "The trial period will expire.",
  "auth-trial-message-2": "Will you continue to use the service?",
  "auth-trial-submit-1": "Cancel",
  "auth-trial-submit-2": "Skip",
  "auth-trial-submit-3": "Continue",
  "auth-trial-toast-success": "Updated successfully.",
  "auth-trial-toast-error": "Update failed.",

  "auth-logo-title": "Logo Upload Request",
  "auth-logo-message": "The logo does not appear to have been uploaded.",
  "auth-logo-submit-1": "Skip",
  "auth-logo-submit-2": "Upload",
  "auth-logo-toast-success": "Updated successfully.",
  "auth-logo-toast-error": "Update failed.",

  "auth-option-title": "Option",
  "auth-option-message":
    "To use this service, an advanced version contract is required.",
  "auth-option-submit-1": "Skip",
  "auth-option-submit-2": "More detail",
};

export default auth;
