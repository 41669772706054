import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Cookies, useCookies } from "react-cookie";
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "./legacy/store";

const getInitialTheme = () => {
  const cookies = new Cookies();
  const savedTheme = cookies.get("theme");
  return savedTheme === "dark" || savedTheme === undefined;
};

export type ThemeState = {
  darkMode: boolean;
};

const initialState = {
  darkMode: getInitialTheme(),
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeTheme: (state) => {
      state.darkMode = !state.darkMode;
    },
    setDarkMode: (state) => {
      state.darkMode = true;
    },
    setLightMode: (state) => {
      state.darkMode = false;
    },
  },
});

export const useTheme = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const [cookies, setCookie] = useCookies(["theme"]);

  React.useEffect(() => {
    const theme = darkMode ? "dark" : "light";
    if (cookies.theme !== theme) {
      const expire = new Date();
      expire.setDate(expire.getDate() + 7);
      setCookie("theme", theme, {
        expires: expire,
        path: "/",
        secure: true,
      });
    }
    document.documentElement.classList.add(theme);
    document.documentElement.classList.remove(
      theme === "dark" ? "light" : "dark"
    );
    document.documentElement.setAttribute("data-theme", theme);
  }, [darkMode, cookies, setCookie]);

  const toggleTheme = () => {
    dispatch(changeTheme());
  };

  return { theme: darkMode, toggleTheme };
};

export const { changeTheme, setDarkMode, setLightMode } = themeSlice.actions;
export default themeSlice.reducer;
