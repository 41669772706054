import { LoginUserAdapter } from "./adapter/login-user";
import { MFAAdapter } from "./adapter/mfa";

import { LoginUser } from "./type/login-user";
import { IAuthRepository } from "./irepo";

export class AuthUsecase {
  private repository: IAuthRepository;
  private error: (error: any) => void;

  constructor(repository: IAuthRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  // LOGIN
  async loginAccount(req: any): Promise<any> {
    try {
      return await this.repository.loginAccount(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // SETUP
  async setupAccount(req: any): Promise<any> {
    try {
      return await this.repository.setupAccount(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async setupVerify(req: any): Promise<any> {
    try {
      return await this.repository.setupVerify(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // MFA
  async setupMFA(req: any): Promise<any> {
    try {
      const data = await this.repository.setupMFA(req);
      return MFAAdapter.toSetupMFAOutputDTO(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async verifyMFA(req: any): Promise<any> {
    try {
      const data = await this.repository.verifyMFA(req);
      return MFAAdapter.toVerifyMFAOutputDTO(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // FORGET
  async resetPassword(req: any): Promise<any> {
    try {
      return await this.repository.resetPassword(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async verifyPassword(req: any): Promise<any> {
    try {
      return await this.repository.verifyPassword(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // TERMS
  async checkTerms(req: any): Promise<any> {
    try {
      return await this.repository.checkTerms(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async verifyTerms(req: any): Promise<any> {
    try {
      return await this.repository.verifyTerms(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // LOGIN USER
  async fetchLoginUser(req: any): Promise<LoginUser> {
    try {
      const [clientData, userData] = await Promise.all([
        this.repository.fetchClient(req),
        this.repository.fetchUser(req),
      ]);
      return LoginUserAdapter.toOutputDTO({ clientData, userData });
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // TRIAL
  async keepTrial(): Promise<any> {
    try {
      return await this.repository.keepTrial();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async skipTrial(): Promise<any> {
    try {
      return await this.repository.skipTrial();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
