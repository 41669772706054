import { ConfigDef } from "../../core/_shared/type/config-def";

export const configDef: ConfigDef = {
  nodes: [
    {
      key: "activeEnergyBurned",
      layer: 1,
      name: "test",
      name_en: "test",
      unit: "cal",
      unit_en: "cal",
    },
    {
      key: "stepCount",
      layer: 1,
      name: "test",
      name_en: "test",
      unit: "cal",
      unit_en: "cal",
    },
  ],
  filters: [
    {
      key: "age",
      name: "年代",
      name_en: "Age",
    },
    {
      key: "gender",
      name: "性別",
      name_en: "Gender",
    },
  ],
};
