"use client";

import * as React from "react";

export function useDialog(init = false) {
  const [isOpen, setIsOpen] = React.useState<boolean>(init);
  const close = () => setIsOpen(false);
  const open = (state: boolean = true) => setIsOpen(state);
  return { isOpen, close, open };
}
