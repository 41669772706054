import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showErrorModal: false,
  showCausalErrorBar: false,
  errorModalMessage: "",
  errorModalMessageDashboard: "",
  errorModalMessageSymptomRanking: "",
  causalErrorBarMessage: "",
  errorType: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setShowErrorModal: (state, action) => {
      state.showErrorModal = action.payload;
    },
    setShowCausalErrorBar: (state, action) => {
      state.showCausalErrorBar = action.payload;
    },
    setErrorModalMessage: (state, action) => {
      state.errorModalMessage = action.payload;
    },
    setErrorModalMessageDashboard: (state, action) => {
      state.errorModalMessageDashboard = action.payload;
    },
    setErrorModalMessageSymptomRanking: (state, action) => {
      state.errorModalMessageSymptomRanking = action.payload;
    },
    setCausalErrorBarMessage: (state, action) => {
      state.causalErrorBarMessage = action.payload;
    },
    setErrorType: (state, action) => {
      state.errorType = action.payload;
    },
  },
});

export const {
  setShowErrorModal,
  setShowCausalErrorBar,
  setErrorModalMessage,
  setErrorModalMessageDashboard,
  setErrorModalMessageSymptomRanking,
  setCausalErrorBarMessage,
  setErrorType,
} = errorSlice.actions;

export const selectShowErrorModal = (state) => state.error.showErrorModal;
export const selectErrorModalMessage = (state) => state.error.errorModalMessage;
export const selectErrorModalMessageDashboard = (state) =>
  state.error.errorModalMessageDashboard;
export const selectErrorModalMessageSymptomRanking = (state) =>
  state.error.errorModalMessageSymptomRanking;
export const selectShowCausalErrorBar = (state) =>
  state.error.showCausalErrorBar;
export const selectCausalErrorBarMessage = (state) =>
  state.error.causalErrorBarMessage;
export const selectErrorType = (state) => state.error.errorType;

export default errorSlice.reducer;
