import { APIStatus } from "../../_shared/type/status";

export class StoreAdapter {
  static toStoreProcessStatus(res: any): APIStatus {
    const output_dto: APIStatus = {
      status: res === true ? "success" : "error",
      data: res,
    };
    return output_dto;
  }
}
