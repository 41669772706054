import { apiClient } from "../../libs/api";

import { DeleteIDCodeReq, EditIDCodeReq } from "./type/id-code";
import { NodeHistogramReq, EditNodeReq } from "./type/node";
import { DeleteGroupReq, AddGroupReq } from "./type/group";
import { UploadFileReq, StoreFileReq } from "./type/store";
import { DatasetDef, DeleteDatasetReq } from "./type/dataset";

import { datasetRes } from "../../tests/data-manager/dataset";

export class DataManagerGateway {
  // INFO
  async fetchInfo(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/stored/info`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }

  // ID CODE
  async fetchIDCode(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/application`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }

  async addIDCode(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/service`;
    const res = await apiClient.post({ url: url });
    return res as any;
  }

  async editIDCode(req: EditIDCodeReq): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/service/category`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  async deleteIDCode(req: DeleteIDCodeReq): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/service`;
    const res = await apiClient.remove({ url: url, body: req });
    return res as any;
  }

  // NODE
  async fetchNode(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/nodes`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }

  async editNode(req: EditNodeReq): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data-manager/nodes`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  async fetchNodeHistogram(req: NodeHistogramReq): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data-manager/node/histogram`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  // GROUP
  async fetchGroup(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/application`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }

  async addGroup(req: AddGroupReq): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/category`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  async editGroup(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/category`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  async deleteGroup(req: DeleteGroupReq): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/category`;
    const res = await apiClient.remove({ url: url, body: req });
    return res as any;
  }

  // STRAGE
  async checkFile(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/check`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }

  async uploadFile(req: UploadFileReq): Promise<any> {
    const query = `store_type=${encodeURIComponent(req.store_type)}`;
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/upload?${query}`;
    const body = new FormData();
    req.files.forEach((file) => {
      body.append("files", file);
    });
    const res = await apiClient.uploadFile({ url: url, body: body });
    return res as any;
  }

  async storeFile(req: StoreFileReq): Promise<any> {
    const query = `consent_type=${encodeURIComponent(req.consent_type)}`;
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/store?${query}`;
    const res = await apiClient.post({ url: url, body: req.data_ids });
    return res as any;
  }

  // DATASET
  async fetchDataset(): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/dataset`;
    // const res = await apiClient.get({ url: url });
    const res = datasetRes;
    return res as any;
  }

  async addDataset(req: DatasetDef): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/dataset`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = true;
    return res as any;
  }

  async editDataset(req: DatasetDef): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/dataset`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = true;
    return res as any;
  }

  async deleteDataset(req: DeleteDatasetReq): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/data_manager/dataset`;
    // const res = await apiClient.remove({ url: url, body: req });
    const res = true;
    return res as any;
  }
}
