import { apiClient } from "../../libs/api";

export class AccountGateway {
  // PROFILE
  async fetchProfile(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }

  async editProfile(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  // MEMBER
  async fetchMember(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/members`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }

  async addMember(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/members`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  async editMember(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/member/user`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  async selfEditMember(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/user`;
    const res = await apiClient.post({ url: url, body: req });
    return res as any;
  }

  async deleteMember(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/members`;
    const res = await apiClient.remove({ url: url, body: req });
    return res as any;
  }

  // INVOICE
  async fetchInvoice(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/receipt`;
    const res = await apiClient.get({ url: url });
    return res as any;
  }
}
