import { MFA } from "../../auth/type/login-user";

export class MFAAdapter {
  static toSetupMFAOutputDTO(res: any): MFA {
    let expire = new Date();
    expire.setMinutes(expire.getMinutes() + res.ExpiresIn);
    return {
      token: res.AccessToken,
      expire: expire,
    };
  }

  static toVerifyMFAOutputDTO(res: any): MFA {
    let expire = new Date();
    expire.setSeconds(expire.getSeconds() + res.ExpiresIn);
    return {
      token: res.AccessToken,
      expire: expire,
    };
  }
}
