import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getDashboard, getMetadata } from "../../libs/legacy/api";
import { dateFormatter } from "../../libs/legacy/helper";

const initialState = {
  dashboardSpinning: false,
  kpiSpinning: false,
  symptomRankingSpinning: false,
  searchConditions: null,
  dashboardData: null,
  userCount: "--",
  causalUserCount: "--",
  userIds: [],
  demographicData: null,
  trendData: null,
  distributionData: null,
  kpiData: null,
  symptomRankingData: null,
  relevanceRankingData: null,
  part: "",
  condition: "",
  address: "",
  startAge: "",
  endAge: "",
  group: "",
  prevLang: "ja",
  startDate: new Date("2022-02-01"),
  endDate: new Date(),
  male: true,
  female: true,
  other: false,
  conditions: null,
  trendViewDataArray: [],
  distributionViewDataArray: [],
  darkMode: true,
  shouldReloadDashboard: false,
  dashboardOutcomeColumns: [],
};

export const fetchMetadata = createAsyncThunk(
  "dashboard/metadata",
  async (conditions) => {
    const response = await getMetadata(conditions);
    return response;
  }
);

export const fetchDashboard = createAsyncThunk(
  "dashboard/fetch_dashboard",
  async (conditions) => {
    const response = await getDashboard(conditions);
    return response;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardSpinning: (state, action) => {
      state.dashboardSpinning = action.payload.data;
    },
    setKpiSpinning: (state, action) => {
      state.kpiSpinning = action.payload.data;
    },
    setSymptomRankingSpinning: (state, action) => {
      state.symptomRankingSpinning = action.payload.data;
    },
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload.data;
    },
    setCausalUserCount: (state, action) => {
      state.causalUserCount = action.payload;
    },
    setPart: (state, action) => {
      state.part = action.payload;
    },
    setCondition: (state, action) => {
      state.condition = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setPrevLang: (state, action) => {
      state.prevLang = action.payload;
    },
    setStartAge: (state, action) => {
      state.startAge = action.payload;
    },
    setEndAge: (state, action) => {
      state.endAge = action.payload;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setMale: (state, action) => {
      state.male = action.payload;
    },
    setFemale: (state, action) => {
      state.female = action.payload;
    },
    setOther: (state, action) => {
      state.other = action.payload;
    },
    setConditions: (state, action) => {
      state.conditions = action.payload;
    },
    setUserIds: (state, action) => {
      state.userIds = action.payload;
    },
    setTrendViewDataArray: (state, action) => {
      state.trendViewDataArray = action.payload;
    },
    setDistributionViewDataArray: (state, action) => {
      state.distributionViewDataArray = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setShouldReloadDashboard: (state, action) => {
      state.shouldReloadDashboard = action.payload;
    },
    setDashboardOutcomeColumns: (state, action) => {
      state.dashboardOutcomeColumns = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetadata.pending, (state) => {
        state.dashboardSpinning = true;
      })
      .addCase(fetchMetadata.fulfilled, (state, action) => {
        state.dashboardSpinning = false;
        state.searchConditions = action.payload;
        state.userIds = action.payload.user_code_list;
        state.male = action.payload.gender.male;
        state.female = action.payload.gender.female;

        const newConditions = {
          userIds: action.payload.user_code_list,
          groups: action.payload.group,
          ages: action.payload.age,
          genders: action.payload.gender,
          start: action.payload.init_date,
          end: dateFormatter(new Date()),
        };
        state.conditions = newConditions;
      })
      .addCase(fetchDashboard.pending, (state) => {
        state.dashboardSpinning = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.dashboardSpinning = false;
        state.dashboardData = action.payload;
        if (action.payload) {
          state.userCount = action.payload.user_count;
          state.demographicData = action.payload.demographic;
          state.trendData = action.payload.trend;
          state.distributionData = action.payload.distribution;
          state.kpiData = action.payload.kpi_trend;
          state.symptomRankingData = action.payload.symptom_ranking;
          state.relevanceRankingData = action.payload.relevance_ranking;
        } else {
          state.userCount = "--";
          state.demographicData = null;
          state.trendData = null;
          state.distributionData = null;
          state.relevanceRankingData = null;
          state.symptomRankingData = null;
        }
      });
  },
});

export const {
  setDashboardSpinning,
  setKpiSpinning,
  setSymptomRankingSpinning,
  setDashboardData,
  setCausalUserCount,
  setPart,
  setCondition,
  setAddress,
  setPrevLang,
  setStartAge,
  setEndAge,
  setGroup,
  setStartDate,
  setEndDate,
  setMale,
  setFemale,
  setOther,
  setConditions,
  setUserIds,
  setTrendViewDataArray,
  setDistributionViewDataArray,
  setDarkMode,
  setShouldReloadDashboard,
  setDashboardOutcomeColumns,
} = dashboardSlice.actions;

export const selectDashboardSpinning = (state) =>
  state.dashboard.dashboardSpinning;
export const selectKpiSpinning = (state) => state.dashboard.kpiSpinning;
export const selectSymptomRankingSpinning = (state) =>
  state.dashboard.symptomRankingSpinning;
export const selectSearchConditions = (state) =>
  state.dashboard.searchConditions;
export const selectUserIds = (state) => state.dashboard.userIds;
export const selectDataboardData = (state) => state.dashboard.dashboardData;
export const selectUserCount = (state) => state.dashboard.userCount;
export const selectCausalUserCount = (state) => state.dashboard.causalUserCount;
export const selectDemographicData = (state) => state.dashboard.demographicData;
export const selectTrendData = (state) => state.dashboard.trendData;
export const selectDistributionData = (state) =>
  state.dashboard.distributionData;
export const selectKpiData = (state) => state.dashboard.kpiData;
export const selectSymptomRankingData = (state) =>
  state.dashboard.symptomRankingData;
export const selectRelevanceRankingData = (state) =>
  state.dashboard.relevanceRankingData;
export const selectPart = (state) => state.dashboard.part;
export const selectCondition = (state) => state.dashboard.condition;
export const selectAddress = (state) => state.dashboard.address;
export const selectPrevLang = (state) => state.dashboard.prevLang;
export const selectStartAge = (state) => state.dashboard.startAge;
export const selectEndAge = (state) => state.dashboard.endAge;
export const selectGroup = (state) => state.dashboard.group;
export const selectStartDate = (state) => state.dashboard.startDate;
export const selectEndDate = (state) => state.dashboard.endDate;
export const selectMale = (state) => state.dashboard.male;
export const selectFemale = (state) => state.dashboard.female;
export const selectOther = (state) => state.dashboard.other;
export const selectConditions = (state) => state.dashboard.conditions;
export const selectTrendViewDataArray = (state) =>
  state.dashboard.trendViewDataArray;
export const selectDistributionViewDataArray = (state) =>
  state.dashboard.distributionViewDataArray;
export const selectDarkMode = (state) => state.dashboard.darkMode;
export const selectShouldReloadDashboard = (state) =>
  state.dashboard.shouldReloadDashboard;
export const selectDashboardOutcomeColumns = (state) =>
  state.dashboard.dashboardOutcomeColumns;

export default dashboardSlice.reducer;
