const auth = {
  "auth-session-title": "セッションの有効期限が切れました",
  "auth-session-message":
    "引き続きサービスをご利用いただくには、再度ログインしてください。",
  "auth-session-submit": "ログイン",

  "auth-login-title": "ログイン",
  "auth-login-message":
    "メールアドレスの確認が完了しました。\nログインして2段階認証の設定を行ってください。",
  "auth-login-email": "メールアドレス",
  "auth-login-password": "パスワード",
  "auth-login-terms-detail": "利用規約の合意",
  "auth-login-submit": "ログイン",
  "auth-login-forget": "ログインできない方はこちら",
  "auth-login-setup": "アカウントをお持ちでない方はこちら",

  "auth-login-email-validate-error": "メールアドレスを確認してください",
  "auth-login-password-validate-error": "パスワードを確認してください",
  "auth-login-api-error":
    "有効なメールアドレスとパスワードを入力してください。",

  "auth-setup-title": "アカウント登録",
  "auth-setup-email": "メールアドレス",
  "auth-setup-password": "パスワード",
  "auth-setup-terms": "利用規約の合意",
  "auth-setup-submit": "登録",
  "auth-setup-complete-title": "アカウント登録",
  "auth-setup-complete-message":
    "確認メールを送信しました、メールの確認をお願いします。",

  "auth-setup-complete-api-error": "Internal Server Error.",

  "auth-setup-email-validate-error": "メールアドレスを確認してください",
  "auth-setup-password-validate-error": "パスワードを確認してください",
  "auth-setup-terms-validate-error": "利用規約を確認してください",
  "auth-setup-terms-api-error": "既にユーザー登録されています。",

  "auth-forget-title": "パスワードの再設定",
  "auth-forget-code": "認証コード",
  "auth-forget-email": "メールアドレス",
  "auth-forget-password": "新しいパスワード",
  "auth-forget-submit": "送信",
  "auth-forget-back": "戻る",
  "auth-forget-complete-message":
    "パスワードが再設定されました。\n以下のリンクから新しいパスワードを利用してログインしてください。",
  "auth-forget-complete-submit": "ログイン",

  "auth-forget-code-validate-error": "認証コードを確認してください",
  "auth-forget-email-validate-error": "メールアドレスを確認してください",
  "auth-forget-password-validate-error": "パスワードを確認してください",
  "auth-forget-api-user-error": "ユーザーが登録されていません。",
  "auth-forget-api-code-error":
    "有効な認証コードとパスワードを入力してください。",

  "auth-mfa-title": "2段階認証",
  "auth-mfa-message-1":
    "xCausalでは2段階認証を行うために、スマートフォン用認証アプリ[Google Authenticator]を使用しています。 \nアプリストアから事前にダウンロードをお願いいたします。",
  "auth-mfa-message-2":
    "2段階認証の再登録をご希望の場合は下に記載の問い合わせからご連絡ください。",
  "auth-mfa-message-3":
    "スマートフォン用認証アプリに表示されている6桁のコードを入力してください。",
  "auth-mfa-code": "認証コード",
  "auth-mfa-submit": "送信",
  "auth-mfa-back": "戻る",

  "auth-mfa-code-validate-error": "認証コードを確認してください",
  "auth-mfa-api-code-error": "有効な認証コードを入力してください。",

  "auth-mfa-terms-title": "利用規約",
  "auth-mfa-terms-message":
    "利用規約が更新されました。\n以下のリンクから内容をご確認いただき、同意の上ご利用ください。",
  "auth-mfa-terms-detail": "利用規約の合意",
  "auth-mfa-terms-submit": "ログイン",

  "auth-mfa-terms-api-error": "Internal Server Error.",

  "auth-trial-title": "トライアル期限",
  "auth-trial-message-1": "トライアル期間がまもなく終了します。",
  "auth-trial-message-2": "引き続き継続してサービスを利用しますか?",
  "auth-trial-submit-1": "終了する",
  "auth-trial-submit-2": "スキップ",
  "auth-trial-submit-3": "利用する",
  "auth-trial-toast-success": "正常に更新されました。",
  "auth-trial-toast-error": "更新に失敗しました。",

  "auth-logo-title": "ロゴアップロード",
  "auth-logo-message":
    "ロゴがアップロードされていないようです。ロゴのアップロードを行なってください。",
  "auth-logo-submit-1": "スキップ",
  "auth-logo-submit-2": "アップロード",

  "auth-option-title": "オプション",
  "auth-option-message":
    "こちらのサービスをご利用になるにはアドバンス・バージョンのご契約が必要です。",
  "auth-option-submit-1": "スキップ",
  "auth-option-submit-2": "詳細確認",
};

export default auth;
