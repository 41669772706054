const dashboard = {
  "dashboard-api-error-1":
    "ユーザ数が解析にあたり不十分のため、解析できません。",
  "dashboard-api-error-2":
    "ユーザ数が解析にあたり不十分のため、絞り込み条件を再設定ください。",
  "dashboard-api-error-3":
    "選択可能なアウトカムノードが0件のため、絞り込み条件を再設定ください。",
  "dashboard-api-error-4":
    "選択可能な関連ノードが0件のため、絞り込み条件を再設定ください。",
  "dashboard-api-error-5":
    "「選択したノード全てを有する人数」が0人です。１人以上になるように関連するノードを設定してください。",

  "dashboard-ranking-label": "ランキング",
  "dashboard-demographics-label": "デモグラフィック",
  "dashboard-trend-label": "トレンド",
  "dashboard-symptom-ranking-label": "不調症状のランキング",
  "dashboard-relevance-ranking-label": "関連度ランキング",
  "dashboard-condition-value-label": "体調の値",
  "dashboard-group-label": "部門",
  "dashboard-distribution-label": "ディストリビューション",
  "dashboard-items-label": "内訳",
  "dashboard-subjects-label": "件",
  "dashboard-incidents-label": "発症件数",
  "dashboard-large-data-label": "データが多い (解析が十分可能)",
  "dashboard-sufficient-data-label": "データがある (解析可能)",
  "dashboard-insufficient-data-label": "データが少ない (解析を推奨しない)",
  "dashboard-degree-relevance-label": "との関連度",
  "dashboard-relationship-label": "トレンド",
  "dashboard-time-series-label": "時系列比較",
  "dashboard-relevance-data-label": "同じ期間での比較",
  "dashboard-heatmap-body-front-label": "前",
  "dashboard-heatmap-body-back-label": "後",
  "dashboard-demographics-ratio-label": "構成比率",
  "dashboard-demographics-condition-level-label": "デモグラフィック",
  "dashboard-related-axis-settings": "設定:",
  "dashboard-strongly-related-items": "と関連の強い項目",
  "dashboard-subordinate": "下位",
  "dashboard-kpi-title": "主要KPIトレンド",
  "dashboard-kpi-popover-button": "設定",
  "dashboard-kpi-reorder-reset-button": "リセット",
  "dashboard-kpi-reorder-save-button": "保存",
  "dashboard-kpi-reorder-max-message": "KPI項目の最大数は8です。",
  "dashboard-kpi-reorder-min-message": "KPI項目の最小数は1です。",

  "demographics-genders-label": "性別",
  "demographics-ages-label": "年代",
};

export default dashboard;
