import store from "../../hooks/legacy/store";
import i18n from "../../i18n";

const unitTranslations = {
  レベル: "Level ",
  体調: "condition",
  気分: "mood",
  回: "times",
  歩: "steps",
  ミリ秒: "ms",
  分: "min",
  時間: "h",
  有無: "",
  天気: "weather",
  とても良い: "Great",
  良い: "Good",
  まあまあ: "So-so",
  とても悪い: "Terrible",
  悪い: "Bad",
  日: " day ",
  平均: "average",
  累計: "cumulative",
  "雨/雪": "Rain/Snow",
  曇り: "Cloudy",
  晴れ: "Sunny",
};

export const translateUnits = (str) => {
  let strCopy = str;
  for (let key of Object.keys(unitTranslations)) {
    if (typeof strCopy === "string") {
      strCopy = strCopy.replaceAll(key, unitTranslations[key]);
    }
  }
  return strCopy;
};

export const roundValue = (value, decimals) => {
  if (value) {
    return value.toFixed(decimals);
  }
  return 0;
};

export const roundValueFormat = (value, formatString) => {
  if (value && formatString) {
    let dec = formatString.split(".")[1][0];
    return value.toFixed(parseInt(dec));
  } else if (value) {
    return value.toFixed(1);
  }
  return value;
};

export const getLabelForValue = (dwh, value, type, en = false) => {
  if (dwh && dwh[type] && dwh[type][value]) {
    if (en && dwh[type][value].name_en !== null) {
      return dwh[type][value].name_en;
    }
    return dwh[type][value].name;
  }
  return value;
};

export const getValueForLabel = (dwh, label, type) => {
  if (dwh && dwh[type] && dwh[type][label]) {
    return dwh[type][label].value;
  }
  return label;
};

export const getUnitForValue = (dwh, value, type, en = false) => {
  if (dwh && dwh[type] && dwh[type][value]) {
    if (en) {
      return dwh[type][value].unit_en;
    }
    return dwh[type][value].unit;
  }
  return value;
};

export const getLabelForNodeValueFromDataset = (data, value, en = false) => {
  if (data && data.columns && data.columns[value]) {
    const obj = data.columns[value];
    if (obj && obj.name_en && en && obj.name_en !== null) {
      return obj.name_en;
    }
    if (obj && obj.name) {
      return obj.name;
    }
  }
  return value;
};

export const getNodeValuesFromDataset = (data, value) => {
  if (data && data.columns && data.columns[value]) {
    const obj = data.columns[value];
    return {
      value: obj.value,
      name: obj.name,
      name_en: obj.name_en,
      type: obj.type,
      unit: obj.unit,
      unit_en: obj.unit_en,
      downsample:
        obj.default_discretization_config.discretization_range.downsample,
      stateNames: obj.default_discretization_config.state_names,
    };
  }
  return {
    value: value,
    name: value,
    type: "",
    unit: "",
    unit_en: "",
    downsample: "",
    stateNames: [],
  };
};

export const getNodeValuesFromPublishment = (data, value) => {
  if (data && data.columns && data.columns[value]) {
    const obj = data.columns[value];
    return {
      value: obj.value,
      name: obj.name,
      name_en: obj.name_en,
      type: obj.type,
      unit: obj.unit,
      unit_en: obj.unit_en,
      downsample:
        obj.default_discretization_config.discretization_range.downsample,
      stateNames: obj.default_discretization_config.state_names,
    };
  }
  return {
    value: value,
    name: value,
    type: "",
    unit: "",
    unit_en: "",
    downsample: "",
    stateNames: [],
  };
};

export const getLabelForNodeValueFromPublishment = (
  data,
  value,
  en = false
) => {
  if (data && data.columns && data.columns[value]) {
    const obj = data.columns[value];
    if (obj && obj.name_en && en && obj.name_en !== null) {
      return obj.name_en;
    }
    if (obj && obj.name) {
      return obj.name;
    }
  }
  return value;
};

export const compareLink = (link, links) => {
  if (links && links.length) {
    for (let item of links) {
      if (
        item.source.id === link.source.id &&
        item.target.id === link.target.id
      ) {
        return true;
      }
    }
  }
  return false;
};

export const numOfDays = (startDate, endDate) => {
  if (startDate && endDate) {
    let date_1 = endDate;
    let date_2 = startDate;

    if (typeof date_1.getMonth !== "function") {
      date_1 = new Date(date_1);
    }

    if (typeof date_2.getMonth !== "function") {
      date_2 = new Date(date_2);
    }

    const days = (date_1, date_2) => {
      let difference = date_1.getTime() - date_2.getTime();
      let TotalDays = Math.floor(parseFloat(difference / (1000 * 3600 * 24)));
      return TotalDays;
    };

    return `(${days(date_1, date_2)} ${i18n.t("common-days-label")})`;
  }
  return "";
};

export const dataGranularityParser = (granularity) => {
  switch (granularity) {
    case 33:
      return "one_day";
    case 66:
      return "one_week";
    case 100:
      return "one_month";
    default:
      return "quarter_day";
  }
};

export const dateFormatter = (date) => {
  let newDate = new Date(date);
  // adjust to JST + 9 - not sure why this is necessary, but it works.
  newDate = new Date(newDate.setTime(newDate.getTime() + 9 * 60 * 60 * 1000));
  if (date && typeof date.getMonth === "function") {
    return `${newDate.toISOString().substring(0, 10)}T00:00:00`;
  }
  return date;
};

export const dayDiff = (startDate, endDate) => {
  let newStartDate =
    typeof startDate === "string" ? new Date(startDate) : startDate;
  let newEndDate = endDate;
  let milliseconds = newEndDate - newStartDate;
  let days = Math.floor(parseFloat(milliseconds / (1000 * 60 * 60 * 24)));
  return days;
};

export const translateCondition = (condition) => {
  const kvPairs = {
    とても悪い: i18n.t("common-very-bad-label"),
    悪い: i18n.t("common-bad-label"),
    まあまあ: i18n.t("common-ok-label"),
    良い: i18n.t("common-good-label"),
    とても良い: i18n.t("common-very-good-label"),
  };
  if (kvPairs[condition]) {
    return kvPairs[condition];
  }
  return condition;
};

export const isAdvanced = () => {
  return store.getState().causalGraph.advancedVersion;
};

export const getModel = () => {
  return store.getState().causalGraph.model;
};

export const getATECalcPath = () => {
  if (isAdvanced()) {
    return "ate_calculation_result";
  }
  return "ate_calculation_table";
};

export const whichBrowser = () => {
  var test = function (regexp) {
    return regexp.test(window.navigator.userAgent);
  };
  switch (true) {
    case test(/edg/i):
      return "Microsoft Edge";
    case test(/trident/i):
      return "Microsoft Internet Explorer";
    case test(/firefox|fxios/i):
      return "Mozilla Firefox";
    case test(/opr\//i):
      return "Opera";
    case test(/ucbrowser/i):
      return "UC Browser";
    case test(/samsungbrowser/i):
      return "Samsung Browser";
    case test(/chrome|chromium|crios/i):
      return "Google Chrome";
    case test(/safari/i):
      return "Apple Safari";
    default:
      return "Other";
  }
};

export const getIsObjectiveNode = (graphData) => {
  if (graphData && graphData.nodes) {
    return graphData.nodes.filter((item) => item.isObjective);
  }
  return [];
};

export const roundGraphValue = (value, decimals) => {
  if (value === null) {
    return "N/A";
  }

  if (value) {
    let returnValue = value.toFixed(decimals);
    if (`${returnValue}` === "-0.00" || `${returnValue}` === "0.00") {
      return 0;
    }
    return returnValue;
  }
  return 0;
};

export const generateGetBoundingClientRect = (x = 0, y = 0) => {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  });
};

export const virtualElement = (x, y) => {
  return {
    getBoundingClientRect: generateGetBoundingClientRect(x + 50, y + 20),
  };
};

export const adjustedATE = (value) => {
  return Math.abs(value).toFixed(2) * 10 + 1;
};
