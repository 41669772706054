const shared = {
  "metadata-error-1":
    "Analysis cannot be performed because the number of users is insufficient for analysis.",
  "metadata-error-2":
    "There was an error loading data. Please try again later.",

  "copied-success": "Copied successfully.",
  "download-success": "Download successfully.",
};

export default shared;
