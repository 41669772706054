import { idCodeDef } from "../../tests/shared/id-code-def";
import { configDef } from "../../tests/shared/config-def";
import { queryDef } from "../../tests/shared/query-def";

import { IdCodeReq } from "./type/id-code-def";

import { apiClient } from "../../libs/api";

export class SharedGateway {
  async fetchConfigDef(): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/config-def`;
    // const res = await apiClient.get({ url: url });
    const res = configDef;
    return res as any;
  }

  async fetchQueryDef(): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/query-def`;
    // const res = await apiClient.get({ url: url });
    const res = queryDef;
    return res as any;
  }

  async fetchIdCodeDef(req: IdCodeReq): Promise<any> {
    // const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/id-code-def`;
    // const res = await apiClient.post({ url: url, body: req });
    const res = idCodeDef;
    return res as any;
  }

  async deleteRedisCache(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/operation/redis/cache`;
    const res = await apiClient.remove({ url: url });
    return res as any;
  }
}
