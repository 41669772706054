import { configureStore } from "@reduxjs/toolkit";

import causalGraphReducer from "./causal-graph";
import dashboardReducer from "./dashboard";
import vendorReducer from "./vendor";
import themeReducer from "../theme";
import errorReducer from "./error";
import graphReducer from "./graph";
import userReducer from "./user";
import infoReducer from "./info";
import dwhReducer from "./dwh";
import uiReducer from "./ui";

const store = configureStore({
  reducer: {
    causalGraph: causalGraphReducer,
    dashboard: dashboardReducer,
    graphDispatch: graphReducer,
    userDispatch: userReducer,
    infoDispatch: infoReducer,
    uiDispatch: uiReducer,
    vendor: vendorReducer,
    error: errorReducer,
    theme: themeReducer,
    dwh: dwhReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
