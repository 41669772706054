const dataManager = {
  // MAIN
  "data-manager-main-title": "Data Manager",
  "data-manager-main-description":
    "On this page, you can manage your data-related settings and other information.",

  "data-manager-main-tab-1": "ID Code",
  "data-manager-main-tab-2": "Node",
  "data-manager-main-tab-3": "Group",

  "data-manager-main-button-1": "Download Sample",
  "data-manager-main-button-2": "Download CSV Checker",
  "data-manager-main-button-3": "Upload",

  // SUMMARY
  "data-manager-summary-widget-1-title": "Active Data Size",
  "data-manager-summary-widget-2-title": "Active ID Code",
  "data-manager-summary-widget-3-title": "Apps Linked ID Code",
  "data-manager-summary-widget-4-title": "Date Range",
  "data-manager-summary-widget-5-title": "Uploaded At",

  // UPLOAD
  "data-manager-upload-dialog-title": "1. Data upload",
  "data-manager-upload-dialog-description":
    "This section temporarily uploads selected data.",

  "data-manager-upload-dialog-close-1": "Cannot be interrupted during upload.",
  "data-manager-upload-dialog-close-2": "Cannot be interrupted during store.",

  "data-manager-upload-dialog-tab-1": "Add",
  "data-manager-upload-dialog-tab-1-description":
    "Newly added to previously uploaded data.",
  "data-manager-upload-dialog-tab-2": "New",
  "data-manager-upload-dialog-tab-2-description":
    "All previously uploaded data will be deleted.",

  "data-manager-upload-dialog-error-1":
    "Data access failed. Please upload data again.",

  "data-manager-upload-dialog-support-1": "Drag & drop files or",
  "data-manager-upload-dialog-support-2": "Browse",
  "data-manager-upload-dialog-support-3": "Supported formats",

  "data-manager-upload-dialog-limit-1": "File Limit",
  "data-manager-upload-dialog-limit-2": "Size Limit",
  "data-manager-upload-dialog-submit-1": "Upload File",
  "data-manager-upload-dialog-submit-2": "Uploading File ...",

  "data-manager-upload-dialog-toast-process-1":
    "File uploading in progress ...",
  "data-manager-upload-dialog-toast-process-2": "Data upload completed.",
  "data-manager-upload-dialog-toast-error-1": "File not selected.",
  "data-manager-upload-dialog-toast-error-2": "Data upload failed.",

  // STORE
  "data-manager-store-dialog-title": "2. Activate uploaded data",
  "data-manager-store-dialog-description":
    "In this section, you can choose to activate or deactivate the uploaded data.",

  "data-manager-store-dialog-widget-1": "Data Size",
  "data-manager-store-dialog-widget-1-error": "Data size limit exceeded.",
  "data-manager-store-dialog-widget-2": "Active ID Code",
  "data-manager-store-dialog-widget-2-error": "Active id code limit exceeded.",
  "data-manager-store-dialog-widget-3": "File Limit",

  "data-manager-store-dialog-submit-1": "Reject",
  "data-manager-store-dialog-submit-2": "Rejecting ...",
  "data-manager-store-dialog-submit-3": "Approve",
  "data-manager-store-dialog-submit-4": "Approving ...",

  "data-manager-store-dialog-toast-process-1": "Approving in progress ...",
  "data-manager-store-dialog-toast-process-2": "Rejecting in progress ...",
  "data-manager-store-dialog-toast-process-3": "Data approve completed ...",
  "data-manager-store-dialog-toast-process-4": "Data reject completed ...",

  "data-manager-store-dialog-toast-error-1": "File not selected.",
  "data-manager-store-dialog-toast-error-2": "Data approve failed.",
  "data-manager-store-dialog-toast-error-3": "Data reject failed.",
  "data-manager-store-dialog-toast-error-4":
    "The max number of files available for selection has been exceeded.",

  // NODE
  "data-manager-node-caption":
    "Follow the steps 1 to 3 to upload the CSV and configure the nodes for analysis.\nNote: Simply uploading the CSV is not sufficient for analysis. Make sure to complete the settings in steps 2 and 3 after uploading.",

  "data-manager-node-section-1-title": "1.【CSV Upload】",
  "data-manager-node-section-1-item-1-title":
    "If the you’d integration option is disabled",
  "data-manager-node-section-1-item-1-content-1-left":
    "Download the CSV sample from the top right icon",
  "data-manager-node-section-1-item-1-content-1-right": "of the screen.",
  "data-manager-node-section-1-item-1-content-2":
    "Using the CSV sample as a reference, create a CSV file containing your identification codes and time series data.",
  "data-manager-node-section-1-item-1-content-3-left":
    "Download the CSV Checker(※) from the top right icon",
  "data-manager-node-section-1-item-1-content-3-right":
    "of the screen and use it to validate the CSV file you created.",
  "data-manager-node-section-1-item-1-content-4":
    "Upload the validated CSV using the 'Upload' button at the top right of the screen.",

  "data-manager-node-section-1-item-2-title":
    "If the you’d integration option is enabled",
  "data-manager-node-section-1-item-2-content-1-left":
    "Download the CSV sample from the top right icon",
  "data-manager-node-section-1-item-2-content-1-right": "of the screen.",
  "data-manager-node-section-1-item-2-content-2":
    "If you don’t have necessary identification codes, select the 'ID Code' tab and click the 'ID Code Issue' button to issue those.",
  "data-manager-node-section-1-item-2-content-3":
    "Using the CSV sample as a reference, create a CSV file with the issued identification codes and time series data.",
  "data-manager-node-section-1-item-2-content-4-left":
    "Download the CSV Checker(※) from the top right icon",
  "data-manager-node-section-1-item-2-content-4-right":
    "of the screen and use it to validate the CSV file you created. This will generate a validatedCSV.",
  "data-manager-node-section-1-item-2-content-5":
    "Upload the validated CSV using the 'Upload' button at the top right of the screen.",

  "data-manager-node-section-1-footer":
    "※ The CSV Checker is for Windows users only. If you are using another operating system, refer to the CSV Checker manual to ensure your CSV is in the correct format.",

  // ID CODE
  "data-manager-id-code-button-1": "ID Code Issue",

  "data-manager-id-code-api-success-1": "The id code was deleted successfully.",
  "data-manager-id-code-api-success-2": "The group was adapted successfully.",
  "data-manager-id-code-api-success-3": "The id code was issued successfully.",

  "data-manager-id-code-export-success": "The id code was export successfully.",

  "data-manager-id-code-api-error-1":
    "he id code was not deleted successfully.",
  "data-manager-id-code-api-error-2": "The group was not adapted successfully.",
  "data-manager-id-code-api-error-3": "Please complete the form.",
  "data-manager-id-code-api-error-4":
    "The id code was not issued successfully.",

  "data-manager-id-code-export-error":
    "The id code was not export successfully.",

  // GROUP
  "data-manager-group-button-1": "Add Group",

  "data-manager-group-api-success-1": "The group was added successfully.",
  "data-manager-group-api-success-2": "The group was deleted successfully",

  "data-manager-group-api-error-1": "The group was not added successfully.",
  "data-manager-group-api-error-2": "The group was not deleted successfully.",
  "data-manager-group-api-error-3": "Please complete the form.",

  "data-manager-group-add-dialog-title": "Add Group",
  "data-manager-group-add-dialog-discription": "Click change when you're done.",
  "data-manager-group-add-dialog-item-1": "Name",
  "data-manager-group-add-dialog-item-2": "Discription",
  "data-manager-group-add-dialog-submit": "Add Group",

  "data-manager-group-delete-dialog-title": "Delete Group",
  "data-manager-group-delete-dialog-discription":
    "Click change when you're done.",
  "data-manager-group-delete-dialog-item-1": "Name",
  "data-manager-group-delete-dialog-item-2": "Discription",
  "data-manager-group-delete-dialog-submit": "Delete Group",
};

export default dataManager;
