import * as React from "react";
import { useLocation } from "react-router-dom";

type ScrollProps = {
  children?: React.ReactNode;
  position?: "top" | "bottom";
};

export function Scroll({ children, position = "top" }: ScrollProps) {
  const location = useLocation();

  React.useLayoutEffect(() => {
    if (position === "top") {
      document.documentElement.scrollTo(0, 0);
    } else if (position === "bottom") {
      document.documentElement.scrollTo(
        0,
        document.documentElement.scrollHeight
      );
    }
  }, [location.pathname, position]);

  return <>{children}</>;
}
