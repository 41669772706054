import * as React from "react";

import { useMutation } from "@tanstack/react-query";
import { useCookies } from "react-cookie";

import { LoginUser } from "../core/auth/type/login-user";
import { initialState } from "../context/auth";
import { useUsecases } from "../store";

export const useAuth = () => {
  const [cookies, , removeCookie] = useCookies(["token", "expire", "terms"]);
  const [token, setToken] = React.useState<string | null>(cookies.token);
  const [loginUser, setLoginUser] = React.useState<LoginUser>(initialState);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLogin, setIsLogin] = React.useState<boolean>(false);

  const { authUsecase } = useUsecases();
  const { mutate: fetchClient } = useMutation({
    mutationFn: (token) => authUsecase.fetchLoginUser({ token: token }),
    onSuccess: (data) => {
      setLoginUser(data as LoginUser);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
      logOut();
    },
  });

  const logOut = React.useCallback(() => {
    removeCookie("token", { path: "/" });
    removeCookie("expire", { path: "/" });
    removeCookie("terms", { path: "/" });

    setIsLogin(false);
    setLoginUser(initialState);
    setIsLoading(false);
  }, [removeCookie]);

  React.useEffect(() => {
    const token = cookies.token;
    const expire = cookies.expire ? new Date(cookies.expire) : null;
    const terms = cookies.terms === "true";

    if (!token || !terms || !expire || new Date() >= expire) {
      logOut();
      return;
    }
    setToken(token);
    setIsLogin(true);

    fetchClient();
  }, [cookies, fetchClient, logOut]);

  return { token, loginUser, logOut, isLogin, isLoading };
};
