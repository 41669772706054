import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDWH } from "../../libs/legacy/api";

const initialState = {
  spinning: false,
  dwhData: null,
};

export const fetchDWHAsync = createAsyncThunk(
  "dwh/fetchDWH",
  async (conditions) => {
    const response = await getDWH(conditions);
    return response;
  }
);

export const dwhSlice = createSlice({
  name: "dwh",
  initialState,
  reducers: {
    startSpinning: (state) => {
      state.spinning = true;
    },
    stopSpinning: (state) => {
      state.spinning = false;
    },
    setDWHData: (state, action) => {
      state.dwhData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDWHAsync.pending, (state) => {
        state.spinning = true;
      })
      .addCase(fetchDWHAsync.fulfilled, (state, action) => {
        state.spinning = false;

        if (action.payload) {
          const data = action.payload;
          const columnsObject = {};
          const bodyObject = {};
          const symptomsObject = {};
          const columnsLabelObject = {};
          const columnsENLabelObject = {};
          const bodyLabelObject = {};
          const symptomsLabelObject = {};

          for (let item of data.column_properties) {
            columnsObject[item.value] = item;
            columnsLabelObject[item.name] = item;
          }
          for (let item of data.column_properties) {
            columnsObject[item.value] = item;
            columnsLabelObject[item.name_en] = item;
          }
          for (let item of data.symptom_body_name) {
            bodyObject[item.value] = item;
            bodyLabelObject[item.name] = item;
          }
          for (let item of data.symptom_name) {
            symptomsObject[item.value] = item;
            symptomsLabelObject[item.name] = item;
          }

          const dwh = {
            columns: columnsObject,
            body: bodyObject,
            symptoms: symptomsObject,
            columnsByLabel: columnsLabelObject,
            bodyByLabel: bodyLabelObject,
            symptomsByLabel: symptomsLabelObject,
            columnsByENLabel: columnsENLabelObject,
          };
          state.dwhData = dwh;
        } else {
          state.dwhData = null;
        }
      });
  },
});

export const { startSpinning, stopSpinning, setDWHData } = dwhSlice.actions;

export const selectSpinner = (state) => state.dwh.spinning;
export const selectDWHData = (state) => state.dwh.dwhData;

export default dwhSlice.reducer;
