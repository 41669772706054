const dataManager = {
  // MAIN
  "data-manager-main-title": "データ管理",
  "data-manager-main-description":
    "このページでは、データに関する設定などを管理することができます。",

  "data-manager-main-tab-1": "識別コード",
  "data-manager-main-tab-2": "ノード",
  "data-manager-main-tab-3": "グループ",

  "data-manager-main-button-1": "サンプルダウンロード",
  "data-manager-main-button-2": "CSVチェッカーダウンロード",
  "data-manager-main-button-3": "アップロード",

  // SUMMARY
  "data-manager-summary-widget-1-title": "データ容量",
  "data-manager-summary-widget-2-title": "有効識別コード数",
  "data-manager-summary-widget-3-title": "アプリ連携済識別コード数",
  "data-manager-summary-widget-4-title": "データ期間",
  "data-manager-summary-widget-5-title": "最終アップロード日時",

  // UPLOAD
  "data-manager-upload-dialog-title": "1. データアップロード",
  "data-manager-upload-dialog-description":
    "このセクションでは選択したデータを一時的にアップロードします。",

  "data-manager-upload-dialog-close-1": "アップロード中は中断できません",
  "data-manager-upload-dialog-close-2": "データ格納中は中断できません",

  "data-manager-upload-dialog-tab-1": "追加",
  "data-manager-upload-dialog-tab-1-description":
    "以前アップロードされたデータに新たに追加されます。",
  "data-manager-upload-dialog-tab-2": "新規",
  "data-manager-upload-dialog-tab-2-description":
    "以前アップロードされたすべてのデータが削除されます。",

  "data-manager-upload-dialog-error-1":
    "データアクセスに失敗しました。\n再度、データアップロードを行ってください。",

  "data-manager-upload-dialog-support-1": "ドラッグ&ドロップ もしくは",
  "data-manager-upload-dialog-support-2": "ファイル選択",
  "data-manager-upload-dialog-support-3": "サポートフォーマット",

  "data-manager-upload-dialog-limit-1": "ファイル数制限",
  "data-manager-upload-dialog-limit-2": "ファイル容量制限",
  "data-manager-upload-dialog-submit-1": "アップロード",
  "data-manager-upload-dialog-submit-2": "アップロード中 ...",

  "data-manager-upload-dialog-toast-process-1": "データアップロード中 ...",
  "data-manager-upload-dialog-toast-process-2":
    "正常にデータアップロードされました。",
  "data-manager-upload-dialog-toast-error-1": "ファイルが選択されていません。",
  "data-manager-upload-dialog-toast-error-2":
    "データアップロードに失敗しました。",

  // STORE
  "data-manager-store-dialog-title": "2. アップロードデータの有効化",
  "data-manager-store-dialog-description":
    "このセクションではアップロードされたデータの有効化、無効化を選択できます。",

  "data-manager-store-dialog-widget-1": "データ容量",
  "data-manager-store-dialog-widget-1-error": "データ容量の制限を超えました。",
  "data-manager-store-dialog-widget-2": "有効識別コード数",
  "data-manager-store-dialog-widget-2-error":
    "有効識別コード数の制限を超えました。",
  "data-manager-store-dialog-widget-3": "ファイル制限",

  "data-manager-store-dialog-submit-1": "無効化",
  "data-manager-store-dialog-submit-2": "無効化中 ...",
  "data-manager-store-dialog-submit-3": "有効化",
  "data-manager-store-dialog-submit-4": "有効化中 ...",

  "data-manager-store-dialog-toast-process-1": "データの有効化中 ...",
  "data-manager-store-dialog-toast-process-2": "データの無効化中 ...",
  "data-manager-store-dialog-toast-process-3":
    "正常にデータの有効化がされました。",
  "data-manager-store-dialog-toast-process-4":
    "正常にデータの無効化がされました。",

  "data-manager-store-dialog-toast-error-1": "ファイルが選択されていません。",
  "data-manager-store-dialog-toast-error-2": "データの有効化に失敗しました。",
  "data-manager-store-dialog-toast-error-3": "データの無効化に失敗しました。",
  "data-manager-store-dialog-toast-error-4":
    "選択可能なファイル数上限を超えました。",

  // NODE
  "data-manager-node-caption":
    "1 ~ 3の手順でCSVをアップロードし解析対象のノードの設定を行います。\nCSVをアップロードしただけでは解析できません。アップロード後に必ず2と3の設定を完了してください。",

  "data-manager-node-section-1-title": "1. 【CSVアップロード】",
  "data-manager-node-section-1-item-1-title": "you’d連携オプションなしの場合",
  "data-manager-node-section-1-item-1-content-1-left": "画面右上の",
  "data-manager-node-section-1-item-1-content-1-right":
    "からCSVサンプルをダウンロード",
  "data-manager-node-section-1-item-1-content-2":
    "CSVサンプルを参考に、任意の識別コードと時系列データを記載したCSVを作成",
  "data-manager-node-section-1-item-1-content-3-left": "画面右上の",
  "data-manager-node-section-1-item-1-content-3-right":
    "からCSVチェッカー(※)をダウンロードし、作成したCSVの検査を行って検査済CSVを出力",
  "data-manager-node-section-1-item-1-content-4":
    "検査済CSVを画面右上の「アップロード」ボタンからアップロード",

  "data-manager-node-section-1-item-2-title": "you’d連携オプションありの場合",
  "data-manager-node-section-1-item-2-content-1-left": "面右上の",
  "data-manager-node-section-1-item-2-content-1-right":
    "からCSVサンプルをダウンロード",
  "data-manager-node-section-1-item-2-content-2":
    "識別コードが未発行の場合、識別コードタブを選択し「識別コード発行」ボタンを押して識別コードを発行",
  "data-manager-node-section-1-item-2-content-3":
    "CSVサンプルを参考に、発行済識別コードと時系列データを記載したCSVを作成",
  "data-manager-node-section-1-item-2-content-4-left": "画面右上の",
  "data-manager-node-section-1-item-2-content-4-right":
    "からCSVチェッカー（※）をダウンロードし、作成したCSVの検査を行って検査済CSVを出力",
  "data-manager-node-section-1-item-2-content-5":
    "検査済CSVを画面右上の「アップロード」ボタンからアップロード",

  "data-manager-node-section-1-footer":
    "※ CSVチェッカーはWindowsユーザー専用です。他のOSをご利用の場合は、CSVチェッカーマニュアルを参照し、正しいフォーマットのCSVを作成してください。",

  // ID CODE
  "data-manager-id-code-button-1": "識別コード発行",

  "data-manager-id-code-api-success-1": "識別コードが正常に削除されました。",
  "data-manager-id-code-api-success-2": "グループが正常に適応されました。",
  "data-manager-id-code-api-success-3": "識別コードが正常に発行されました。",

  "data-manager-id-code-export-success": "正常にエクスポートされました。",

  "data-manager-id-code-api-error-1":
    "識別コード削除が正常に行われませんでした。",
  "data-manager-id-code-api-error-2":
    "グループ適応が正常に行われませんました。",
  "data-manager-id-code-api-error-3": "入力されていない項目があります。",
  "data-manager-id-code-api-error-4": "識別コードの発行に失敗しました。",

  "data-manager-id-code-export-error": "エクスポートに失敗しました",

  // GROUP
  "data-manager-group-button-1": "グループ追加",

  "data-manager-group-api-success-1": "グループが正常に追加されました。",
  "data-manager-group-api-success-2": "グループが正常に削除されました。",

  "data-manager-group-api-error-1": "グループ追加が正常に行われませんでした。",
  "data-manager-group-api-error-2": "グループ削除が正常に行われませんでした。",
  "data-manager-group-api-error-3": "入力されていない項目があります。",

  "data-manager-group-add-dialog-title": "グループ追加",
  "data-manager-group-add-dialog-discription":
    "反映するにはグループ追加ボタンを押してください。",
  "data-manager-group-add-dialog-item-1": "名前",
  "data-manager-group-add-dialog-item-2": "説明",
  "data-manager-group-add-dialog-submit": "グループ追加",

  "data-manager-group-delete-dialog-title": "グループ削除",
  "data-manager-group-delete-dialog-discription":
    "反映するにはグループ削除ボタンを押してください。",
  "data-manager-group-delete-dialog-item-1": "名前",
  "data-manager-group-delete-dialog-item-2": "説明",
  "data-manager-group-delete-dialog-submit": "グループ削除",
};

export default dataManager;
