import * as React from "react";

import { useLocation, Navigate, Outlet } from "react-router-dom";

import { navLocale, useLocale } from "../libs/locale";
import { useAuthentication } from "./auth";

export const AuthRoute: React.FC = () => {
  const { loginUser, isLogin, isLoading } = useAuthentication();
  const { locale } = useLocale();
  const location = useLocation();

  if (isLoading) {
    return <div style={{ height: "100vh" }} />;
  }

  if (!isLogin) {
    return (
      <Navigate to={navLocale("/login")} state={{ from: location }} replace />
    );
  }

  if (
    !loginUser.client.id &&
    !location.pathname.startsWith(locale + "/account/setup")
  ) {
    return (
      <Navigate
        to={navLocale("/account/setup")}
        state={{ from: location }}
        replace
      />
    );
  }

  if (
    loginUser.client.id &&
    location.pathname.startsWith(locale + "/account/setup")
  ) {
    return (
      <Navigate to={navLocale("/account")} state={{ from: location }} replace />
    );
  }

  return <Outlet />;
};
