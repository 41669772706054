const graph = {
  "causal-graph-api-error-1": "The selected project does not exist.",
  "causal-graph-api-error-2": "The selected project is not valid.",
  "causal-graph-api-error-3":
    "Please select another node because the specified node definition does not exist.",
  "causal-graph-api-error-4":
    "There is no project associated with the generated dataset.",
  "causal-graph-api-error-5":
    "The project associated with the generated dataset is not valid.",
  "causal-graph-api-error-6":
    "There are 0 records in the dataset, so please select another node.",
  "causal-graph-api-error-7": "The selected dataset does not exist.",
  "causal-graph-api-error-8": "The selected dataset is not valid.",
  "causal-graph-api-error-9":
    "Since the number of nodes has reached the upper limit, please reduce the number of nodes and generate the graph again.",
  "causal-graph-api-error-10":
    "There is no project associated with the generated model.",
  "causal-graph-api-error-11":
    "The project linked to the model to be generated is not valid.",
  "causal-graph-api-error-12":
    "There is no dataset associated with the generated model.",
  "causal-graph-api-error-13": "The selected model does not exist.",
  "causal-graph-api-error-14": "The selected model is not valid.",
  "causal-graph-api-error-15":
    "This model is not editable because it failed to save.",
  "causal-graph-api-error-16":
    "Please add another node as it overlaps the node you are currently viewing in the graph.",
  "causal-graph-api-error-17":
    "Nodes cannot be added because the number of nodes has reached the upper limit.",
  "causal-graph-api-error-18": "This model does not exist.",
  "causal-graph-api-error-19": "This model is not valid.",
  "causal-graph-api-error-20":
    "The node you are trying to delete does not exist in the graph.",
  "causal-graph-api-error-21":
    "The edge you are trying to add is prohibited and cannot be added.",
  "causal-graph-api-error-22":
    "Adding this edge will cycle the graph, so add another edge.",
  "causal-graph-api-error-23":
    "The number of nodes has reached the upper limit, so please reduce the number of nodes and add the edge again.",
  "causal-graph-api-error-24":
    "Please select another node because the specified node definition does not exist.",
  "causal-graph-api-error-25":
    "The specified node does not exist, so please select another node.",
  "causal-graph-api-error-26":
    "The edge you are trying to remove does not exist in the graph.",
  "causal-graph-api-error-27":
    "Since there is a node whose number of levels has reached the upper limit in this model, please reduce the number of levels of that node and generate the graph again.",
  "causal-graph-api-error-28":
    "The specified edge rule is forbidden, please specify another edge.",
  "causal-graph-api-error-29":
    "The specified maximum number of parent nodes is not valid, please set it again.",
  "causal-graph-api-error-30":
    "This model cannot be generated because the graph is cyclic.",
  "causal-graph-api-error-31": "This model does not exist and cannot be saved.",
  "causal-graph-api-error-32": "This model is not valid and cannot be saved.",
  "causal-graph-api-error-33":
    "Since there is no project associated with this model, it cannot be saved.",
  "causal-graph-api-error-34":
    "The project associated with this model is not valid and cannot be saved.",
  "causal-graph-api-error-35": "This model has already been saved.",
  "causal-graph-api-error-36":
    "Cannot get the list of intervention operations because this model does not exist.",
  "causal-graph-api-error-37":
    "The list of intervention operations cannot be retrieved because this model is not valid.",
  "causal-graph-api-error-38":
    "The specified intervention variable cannot be manipulated because it is a child node of the outcome variable.",
  "causal-graph-api-error-39":
    "Intervention operation is not possible because there is no project associated with this model.",
  "causal-graph-api-error-40":
    "The intervention operation cannot be performed because the project associated with this model is not valid.",
  "causal-graph-api-error-41":
    "Intervention operation is not possible because there is no dataset associated with this model.",
  "causal-graph-api-error-42":
    "Intervention cannot be performed because the dataset associated with this model is not valid.",
  "causal-graph-api-error-43":
    "Intervention is not possible because this model does not exist.",
  "causal-graph-api-error-44":
    "Intervention is not possible because this model is not valid.",
  "causal-graph-api-error-45":
    "Intervention operation cannot be performed because the specified intervention variable or outcome variable does not exist.",
  "causal-graph-api-error-46":
    "Intervention operation cannot be performed because the level of the specified intervention variable does not exist.",
  "causal-graph-api-error-47":
    "Cannot get the list of estimation results because this model does not exist.",
  "causal-graph-api-error-48":
    "Cannot get the list of estimation results because this model is not valid.",
  "causal-graph-api-error-49":
    "Cannot get estimation result because this estimation result does not exist.",
  "causal-graph-api-error-50":
    "Cannot get estimation result because this estimation result is not valid.",
  "causal-graph-api-error-51":
    "You cannot change the name of the estimation result because this estimation result does not exist.",
  "causal-graph-api-error-52":
    "You cannot change the name of the estimation result because this estimation result is not valid.",
  "causal-graph-api-error-53":
    "Cannot delete estimation result because this estimation result does not exist.",
  "causal-graph-api-error-54":
    "Cannot delete estimation result because this estimation result is not valid.",
  "causal-graph-api-error-55":
    "Estimation results cannot be obtained because there is no project associated with this model.",
  "causal-graph-api-error-56":
    "Estimation results cannot be obtained because the project associated with this model is not valid.",
  "causal-graph-api-error-57":
    "The estimation result cannot be obtained because the dataset associated with this model does not exist.",
  "causal-graph-api-error-58":
    "The estimation results cannot be obtained because the dataset associated with this model is not valid.",
  "causal-graph-api-error-59":
    "This model does not exist, so no estimation results can be obtained.",
  "causal-graph-api-error-60":
    "This model is not valid, so no estimation results can be obtained.",

  "causal-graph-population-label": "Population Size",
  "causal-graph-population-percentage-label": "% of ID Codes",
  "causal-graph-title": "Causal Inference",
  "causal-graph-time-gran-label": "Time Granularity Settings:",
  "causal-graph-outcome-node-label": "Set the node to be analyzed:",
  "causal-graph-outcome-node": "Outcome Node",
  "causal-graph-treatment-node": "Treatment Node",
  "causal-graph-relevance-node-label": "Relevance Node Settings:",
  "causal-graph-auto-select-label": "Recommended by",
  "causal-graph-mancount-label": "Number of people with the selected nodes: ",
  "causal-graph-mancount-unit": "",
  "causal-graph-time-lag": "Time Lag",
  "causal-graph-time-lag-label": "Time lag settings:",
  "causal-graph-outcome-node-label2": "Set the node to be analyzed",
  "causal-graph-relevance-node-label2": "Relevance Node",
  "causal-graph-project-name": "Project: ",
  "causal-graph-graph-name": "Graph: ",
  "causal-graph-create-graph-button": "Create New Graph",
  "causal-graph-load-graph-button": "Load Saved Graph",
  "causal-graph-list-project-name": "Project Name",
  "causal-graph-list-create-date": "Created Date",
  "causal-graph-list-update-date": "Updated Date",
  "causal-graph-list-graph-name": "Graph Name",
  "causal-graph-edit-graph-button": "Edit Graph",
  "causal-graph-direct-indirect-button": "NIE/NDE",
  "causal-graph-target-node-selection-button": "Target Node Selection",
  "causal-graph-select-target-node-error": "Please select a target node.",
  "causal-graph-causal-inference-button": "Causal Inference",
  "causal-graph-delete-label-edge-button": "Delete Edge",
  "causal-graph-delete-label-node-button": "Delete Node",
  "causal-graph-reverse-label-edge-button": "Reverse Edge",
  "causal-graph-add-edge-button": "Add Edge",
  "causal-graph-add-node-button": "Add Node",
  "causal-graph-estimate-button": "Estimate Effects",
  "causal-graph-load-interventions-button": "Load Intervention",
  "causal-graph-create-report-button": "Create Report",
  "causal-graph-save-graph-button": "Save",
  "causal-graph-load-graph-short-button": "Load",
  "causal-graph-reset-button": "Reset",
  "causal-graph-left-click-rotate-label": "Left click: rotate.",
  "causal-graph-mouse-wheel-zoom-label": "Mouse-wheel/middle-click: zoom.",
  "causal-graph-right-click-pan-label": "Right-click: pan.",
  "causal-graph-left-click-pan-label": "Left-click: pan.",
  "causal-graph-outcome-name-label": "Outcome Node",
  "causal-graph-intervention-variable-label": "Intervention Node",
  "causal-graph-di-target-name-label": "Parameter Node",

  "causal-graph-option-switch-label": "Causal Inference Option",
  "causal-graph-basic-version-label": "Basic",
  "causal-graph-advanced-version-label": "Advanced",
  "causal-graph-robustness-label": "Robustness",
  "causal-graph-robust-evaluation-button": "Perform Robustness Assessment",
  "causal-graph-robust-evaluation-1":
    "Test the robustness of the mean treatment effects model by the computed propensity score criterion.",
  "causal-graph-robust-evaluation-2.1":
    "By the following two calculation processes, if the ",
  "causal-graph-robust-evaluation-2.2": "robustness score ",
  "causal-graph-robust-evaluation-2.3":
    "is greater than or equal to 0.4, the robustness test has passed.",
  "causal-graph-robust-evaluation-3":
    "The process will take some time, so you will be notified by a popup when finished.",
  "causal-graph-robust-evaluation-random-common": "Random Common Cause",
  "causal-graph-robust-evaluation-random-detail":
    "Add a random variable independent of the outcome and intervention variables to the causal graph as a common cause, and verify the presence or absence of variation in the estimated results.",
  "causal-graph-robust-evaluation-data-subset": "Validation with Data Subset",
  "causal-graph-robust-evaluation-data-subset-detail":
    "We estimate the causal effect using a randomly extracted subset of the data set, and verify the presence or absence of variation in the estimated results.",
  "causal-graph-robust-evaluation-complete":
    "The robustness evaluation is finished.",
  "causal-graph-robust-test-label": "Test",
  "causal-graph-robust-status-label": "Status",
  "causal-graph-robust-p-value-label": "P-Value",
  "causal-graph-robust-judgement-label": "Judgement",
  "causal-graph-robust-message-label": "Message",
  "causal-graph-robust-dash-label": "ー",
  "causal-graph-robust-dots-label": "........................",
  "causal-graph-robust-processing-label": "Processing",
  "causal-graph-robust-finished-label": "Robustness evaluation complete.",
  "causal-graph-robust-pass-label": "Good",
  "causal-graph-robust-fail-label": "No Good",
  "causal-graph-random-cause-label-1": "Random Common Cause Test",
  "causal-graph-random-cause-label-2": "",
  "causal-graph-data-subset-test-label-1": "Data Subset Test",
  "causal-graph-data-subset-test-label-2": "",
  "causal-graph-comprehensive-judgement-label": "Comprehensive Judgement",

  "causal-graph-go-to-results-button": "Go to Results",
  "causal-graph-expected-difference-label":
    "Expected difference before and after intervention",
  "causal-graph-save-intervention-button": "Save Intervention",
  "causal-graph-clear-intervention-button": "Clear Intervention",
  "causal-graph-selected-nodes-label": "Selected Nodes",
  "causal-graph-selected-edges-label": "Selected Edges",
  "causal-graph-select-label-intervention-label":
    "Please select an intervention node",
  "causal-graph-specify-conditions-label": "Please specify node conditions",
  "causal-graph-probability-label":
    "Probability distribution after intervention",
  "causal-graph-delete-label": "Delete",
  "causal-graph-select-label": "Select",
  "causal-graph-deselect-label": "Deselect",
  "causal-graph-reverse-label": "Reverse",
  "causal-graph-confirm-button": "Confirm",
  "causal-graph-edit-name-button": "Edit Name",
  "causal-graph-average-label": "Average Effect",
  "causal-graph-average-label-advanced": "ATE(continuous value based)",
  "causal-graph-mutual-label": "Mutual Information",
  "causal-graph-cross-label": "Cross-correlation",
  "causal-graph-new-effect-label-advanced": "ATE(discrete value based)",
  "causal-graph-time-scale-label": "Time scale",
  "causal-graph-selection-box-header": "Selection",
  "causal-graph-unit-label": "Unit",
  "causal-graph-error-select-2-nodes":
    "Please select the 2 nodes you wish to connect.",
  "causal-graph-error-select-1-node": "Please select only 1 node.",
  "causal-graph-error-select-outcome-node":
    "Please select the node you want to make the outcome node.",
  "causal-graph-error-select-treatment-node":
    "Please select the node you want to make the treatment node.",
  "causal-graph-possible-edges-label": "Edges can be added to",
  "causal-graph-add-button": "Add",
  "causal-graph-cancel-button": "Cancel",
  "causal-graph-select-node-text": "Please select a node to add",
  "causal-graph-select-node-label": "Select a node",
  "causal-graph-node-name-label": "Node Name",
  "causal-graph-submit-button": "Add",
  "causal-graph-enter-name-label": "Please enter a name",
  "causal-graph-save-graph-label": "Please enter a name",
  "causal-graph-intervention-label": "Intervention",
  "causal-graph-date-saved-label": "Date Saved",
  "causal-graph-analysis-date-label": "Analysis Date",
  "causal-graph-intervention-list-label": "Intervention List",
  "causal-graph-intervention-saved-label": "Intervention Saved",
  "causal-graph-intervention-name-label": "Intervention Name",
  "causal-graph-before-intervention-label": "Control group(median level)",
  "causal-graph-after-intervention-label":
    "Treatment group(intervention level)",
  "causal-graph-before-intervention-short-label": "Control group(median level)",
  "causal-graph-after-intervention-short-label":
    "Treatment group(intervention level)",
  "causal-graph-level-label": "Level",
  "causal-graph-range-label": "Range",
  "causal-graph-average-value-label": "Average Value",
  "causal-graph-probability-short-label": "Probability",
  "causal-graph-intervention-mean-label": "Intervention Mean",
  "causal-graph-expected-value-label": "Expected Value",
  "causal-graph-difference-label": "Difference",
  "causal-graph-company-name-label": "Company Name",
  "causal-graph-project-name-label": "Project Name",
  "causal-graph-model-name-label": "Model Name",
  "causal-graph-conditions-label": "Conditions",
  "causal-graph-groups-label": "Groups",
  "causal-graph-period-label": "Period",
  "causal-graph-outcome-label": "Outcome",
  "causal-graph-relevant-node-label": "Relevant Node",
  "causal-graph-intervention-operation-label": "Intervention Operation",
  "causal-graph-intervention-level-label": "Intervention Level",
  "causal-graph-expected-outcome-label": "Expected Outcome",
  "causal-graph-difference-before-after-label":
    "Difference Before and After Intervention",
  "causal-graph-edge-label": "Edge",
  "causal-graph-model-label": "Model",
  "causal-graph-correlation-label": "Correlation",
  "causal-graph-name-label": "Graph",
  "causal-graph-item-name-label": "Item Name",
  "causal-graph-relevance-rank-label": "Relevance Rank",
  "causal-graph-number-of-records-label": "Number of Records",
  "causal-graph-deficit-rate-label": "Deficit Rate",
  "causal-graph-report-type-label": "Please select the type of report",
  "causal-graph-create-button": "Create",
  "causal-graph-estimation-results-label": "Display estimation results",
  "causal-graph-compare-estimation-label":
    "Compare and display estimation results",
  "causal-graph-view-report-label": "View Report",
  "causal-graph-print-label": "Print",
  "causal-graph-close-label": "Close",
  "causal-graph-graph-model-label": "Graph Model",
  "causal-graph-edge-ATE-label": "Edge Value: ATE",
  "causal-graph-before-direct-label": "Before Direct Causal Effect Estimation",
  "causal-graph-after-direct-label": "After Direct Causal Effect Estimation",
  "causal-graph-pre-probability-label": "Pre-intervention Probability",
  "causal-graph-post-probability-label": "Post-intervention Probability",
  "causal-graph-distribution-before-after-label":
    "Distribution Table Before and After Intervention",
  "causal-graph-relationship-table-label": "Relationship Table",
  "causal-graph-reference-information-label": "Reference Information",
  "causal-graph-data-information-label": "Data Information",
  "causal-graph-edge-parameters-label": "[For development] Edge Parameters",
  "causal-graph-update-model-button": "Update",
  "causal-graph-probabilty-before-after-label":
    "Probability Before / After Intervention",
  "causal-graph-before-short-label": "Before",
  "causal-graph-after-short-label": "After",
  "causal-graph-enter-number-label": "Please enter a number",
  "causal-graph-cannot-delete-objective-label":
    "Outcome nodes cannot be deleted.",
  "causal-graph-must-select-node-label": "You must select a node",
  "causal-graph-must-select-edge-label": "You must select an edge",
  "causal-graph-intervention-effect-label": "Intervention Effect",
  "causal-graph-weeks-label": " Weeks",
  "causal-graph-months-label": " Months",
  "causal-graph-outcome-node-button1": "Change",
  "causal-graph-outcome-node-button2": "Outcome Node",
  "causal-graph-treatment-node-button1": "Change",
  "causal-graph-treatment-node-button2": "Treatment Node",
  "causal-graph-cannot-reverse-edge": "Edge reversal not possible.",

  "causal-graph-looped-graph-error":
    "※Cannot be added because a loop (circulation) occurs on the edge",
  "causal-graph-too-large-target-error":
    "※Cannot be added because it conflicts with the time series set by lag (future → past)",
  "causal-graph-too-large objective-error":
    "※Cannot add because outcome node lag is greater than related node lag",
  "causal-graph-column-not-found-error": "※Specified node not found",
  "causal-graph-not-available-label":
    "※Cannot be added due to prohibition rules",

  "causal-graph-looped-graph-error-reverse":
    "Edge reversal is not possible because it creates a loop on the edge.",
  "causal-graph-too-large-target-error-reverse":
    "Edge reversal is not possible as it conflicts with the time series set by lag.",
  "causal-graph-too-large objective-error-reverse":
    "Edge reversal is not possible as it conflicts with the time series set by lag.",
  "causal-graph-column-not-found-error-reverse":
    "Edge reversal is not possible because the specified node cannot be found.",
  "causal-graph-not-available-label-reverse": "Edge reversal is not possible.",

  "causal-graph-distribution-y-axis-label":
    "Probability Distribution of Outcome Node",
  "causal-graph-histogram-y-axis-label": "Outcome Node Probabilities",
  "causal-graph-distribution-y-axis-label-treatment":
    "Probability Distribution of Treatment Node",
  "causal-graph-histogram-y-axis-label-treatment":
    "Treatment Node Probabilities",

  "causal-graph-probability-dist-label": "Probability",
  "causal-graph-center-label": "Middle",
  "causal-graph-load-existing-graph-label": "(Load from Saved Graph)",
  "causal-graph-check-nodes-label": "Check Nodes",
  "causal-graph-focus": "Focus",

  "causal-graph-treament-mode": "Treatment Mode",
  "causal-graph-treament-node": "Treatment Node",
  "causal-graph-target-node": "Outcome Node",
  "causal-graph-source-node": "Intervention Node",
  "causal-graph-mediator-node": "Parameter Node",
  "causal-graph-choose-target": "Please choose an outcome node in the graph",
  "causal-graph-choose-mediator":
    "Please choose outcome and intervention nodes",
  "causal-graph-no-mediators-available": "No parameter nodes are available",
  "causal-graph-overall-causal-effect": "Overall Causal Effect",
  "causal-graph-direct-effect": "Direct Effect",
  "causal-graph-indirect-effect": "Indirect Effect",
  "causal-graph-robustness-fail-missing-confounders-message":
    "Confounders may be missing",
  "causal-graph-robustness-fail-low-data-message": "Data may be insufficient",
  "causal-graph-robustness-pass-message": "Good robustness score",
  "causal-graph-expected-diff": "Difference of Expected Value",
  "causal-graph-journal-search-button": "Search",
  "causal-graph-journal-next-button": "Load More",
  "causal-graph-search-1": "Find Scientific Literature",
  "causal-graph-search-2":
    "Search for related papers from the Semantic Scholar database.",
  "causal-graph-hover-node-message":
    "Right-click or double-click to select a node.",
  "causal-graph-hover-edge-message":
    "Right-click or double-click to select an edge.",
  "causal-graph-rename-title": "Please enter a graph name.",

  "columns-user-error-message":
    "The number of people with the selected nodes is 0. Please set related nodes to be one or more people.",

  "strat-button": "Stratification Settings",
  "strat-settings-label": "Stratification Variable Settings:",
  "strat-node": "Stratification Variable",
  "strat-group": "Stratification Group",
  "strat-range": "Stratification Range",
  "strat-layout-button": "Stratified Causal Inference",
  "number-of-records": "Number of Records",
  "causal-graph-distribution-y-axis-label-strat":
    "Probability Distribution of Stratification Variables",
  "strat-min-max-message1": "Please choose a value between",
  "strat-min-max-message2": "and",
  "strat-table-title1": "In case of intervention on ",
  "strat-table-title2": "each layer of ",

  "choose-algorithm-button": "Detailed Settings",
  "choose-algorithm-text": "Please select a causal search algorithm",
  "hillclimb-algo": "Hillclimb Algorithm",
  "pc-algo": "PC Algorithm",
  "mmhc-algo": "MMHC  Algorithm",

  "hillclimb-info-title": "Hill Climb Exploration (BDeU)",
  "hillclimb-info-text":
    "It sequentially searches the causal graph so that it has a high degree of fit to the data. While it can efficiently discover graphs that fit the data, it is also a type of approximate solution and locally optimal. There is a possibility of falling into a solution.",
  "pc-info-title": "PC(Peter-Clark) algorithm",
  "pc-info-text":
    "Infers the causal graph structure while determining conditional independence using data. While it is possible to infer dependencies based on statistical reliability, Since the direction of edges is determined based on the method, the direction of cause and effect may not be estimated correctly. Also, the computational cost is generally higher than the hill-climb method.",
  "mmhc-info-title": "MMHC (Max-Min Hill-Climbing) algorithm",
  "mmhc-info-text":
    "Using the dependencies obtained by the PC method, the structure of the causal graph is estimated by the hill-climb method. The structure is estimated based on statistical dependencies and the goodness of fit to the data. While possible, it is the most computationally expensive.",
};

export default graph;
