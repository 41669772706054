import { InvoiceAdapter } from "../account/adapter/invoice";
import { ProfileAdapter } from "../account/adapter/profile";
import { MemberAdapter } from "../account/adapter/member";

import { IAccountRepository } from "./irepo";

export class AccountUsecase {
  private repository: IAccountRepository;
  private error: (error: any) => void;

  constructor(repository: IAccountRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  // PROFILE
  async fetchProfile(): Promise<any> {
    try {
      const data = await this.repository.fetchProfile();
      return ProfileAdapter.toOutputDTO(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editProfile(req: any): Promise<any> {
    try {
      return await this.repository.editProfile(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // MEMBER
  async fetchMember(): Promise<any> {
    try {
      const data = await this.repository.fetchMember();
      return MemberAdapter.toOutputDTO(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async addMember(req: any): Promise<any> {
    try {
      return await this.repository.addMember(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editMember(req: any): Promise<any> {
    try {
      return await this.repository.editMember(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async selfEditMember(req: any): Promise<any> {
    try {
      return await this.repository.selfEditMember(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async deleteMember(req: any): Promise<any> {
    try {
      return await this.repository.deleteMember(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // INVOICE
  async fetchInvoice(): Promise<any> {
    try {
      const data = await this.repository.fetchInvoice();
      return InvoiceAdapter.toOutputDTO(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
