import { IdCodeDef, IdCodeReq } from "./type/id-code-def";
import { ConfigDef } from "./type/config-def";
import { QueryDef } from "./type/query-def";

import { ISharedRepository } from "./irepo";

export class SharedUsecase {
  private repository: ISharedRepository;
  private error: (error: any) => void;

  constructor(repository: ISharedRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  async fetchConfigDef(): Promise<ConfigDef> {
    try {
      return await this.repository.fetchConfigDef();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async fetchQueryDef(): Promise<QueryDef> {
    try {
      return await this.repository.fetchQueryDef();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async fetchIdCodeDef(req: IdCodeReq): Promise<IdCodeDef> {
    try {
      return await this.repository.fetchIdCodeDef(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async deleteRedisCache(): Promise<boolean> {
    try {
      return await this.repository.deleteRedisCache();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
