import { QueryDef } from "../../core/_shared/type/query-def";

export const queryDef: QueryDef = {
  nodes: [
    { id: 1, key: "activeEnergyBurned" },
    { id: 1, key: "stepCount" },
  ],
  filters: [
    {
      id: 1,
      key: "age",
      attrs: [
        { id: 1, name: "10s", value: true },
        { id: 2, name: "20s", value: true },
        { id: 3, name: "30s", value: false },
        { id: 4, name: "40s", value: false },
      ],
      is_range: true,
    },
    {
      id: 2,
      key: "gender",
      attrs: [
        { id: 1, name: "Male", value: true },
        { id: 2, name: "Female", value: true },
        { id: 3, name: "Other", value: false },
      ],
      is_range: true,
    },
  ],
  range: {
    start: new Date("1999-02-01"),
    end: new Date("2024-12-01"),
  },
  id_code_group: [
    { id: 1, name: "Group 1", value: true },
    { id: 2, name: "Group 2", value: true },
    { id: 3, name: "Group 3", value: false },
  ],
  configs: {
    time_grain: ["one_day", "one_month", "one_year"],
    graph_algo: ["bdeu", "pc", "mmhc"],
    inference_algo: ["dowhy", "pgmpy"],
  },
};
