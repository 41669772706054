import { DeleteGroupReq, Group } from "../type/group";
import { APIStatus } from "../../_shared/type/status";

export class GroupAdapter {
  static toDeleteGroupInputDTO(req: DeleteGroupReq): any {
    const input_dto = {
      category_id: req.id,
    };
    return input_dto;
  }

  static toGroupOutputDTO(res: any): Group[] {
    const output_dto: Group[] = res.subject_group
      ? res.subject_group.map((e: any) => ({
          id: e.id,
          name: e.name,
          description: e.description,
        }))
      : [];
    return output_dto;
  }

  static toGroupStatus(res: any): APIStatus {
    const output_dto: APIStatus = {
      status: res.status,
      data: res.data,
    };
    return output_dto;
  }
}
