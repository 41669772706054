import * as React from "react";

import { ErrorDialog } from "../pages/_shared/_dialog/error";
import { useDialog } from "../components/ui/use-dialog";

type Errors = {
  error: (error: any) => void;
};

const ErrorContext = React.createContext<Errors | null>(null);

export function ErrorProvider({ children }: { children: React.ReactNode }) {
  const [error, setError] = React.useState<any>(null);
  const dialog = useDialog();

  const errors: Errors = {
    error: setError,
  };

  React.useEffect(() => {
    if (error?.response?.status === 401) {
      dialog.open();
    }
  }, [error, dialog]);

  return (
    <ErrorContext.Provider value={errors}>
      {children}
      {dialog.isOpen && (
        <ErrorDialog
          open={dialog.isOpen}
          onOpenChange={dialog.close}
          error={setError}
        />
      )}
    </ErrorContext.Provider>
  );
}

export function useError() {
  const context = React.useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
}
