import { DeleteGroupReq, EditGroupReq, AddGroupReq, Group } from "./type/group";
import { NodeHistogramReq, EditNodeReq, Histogram, Node } from "./type/node";
import { DeleteIDCodeReq, EditIDCodeReq, IDCode } from "./type/id-code";
import { UploadFileReq, StoreFileReq, FileInfo } from "./type/store";
import { APIStatus } from "../_shared/type/status";
import { StoreInfo } from "./type/info";

import { IDCodeAdapter } from "./adapter/id-code";
import { StoreAdapter } from "./adapter/store";
import { GroupAdapter } from "./adapter/group";
import { NodeAdapter } from "./adapter/node";

import { IDataManagerRepository } from "./irepo";

export class DataManagerUsecase {
  private repository: IDataManagerRepository;
  private error: (error: any) => void;

  constructor(repository: IDataManagerRepository, error: (error: any) => void) {
    this.repository = repository;
    this.error = error;
  }

  // INFO
  async fetchInfo(): Promise<StoreInfo> {
    try {
      return await this.repository.fetchInfo();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // ID CODE
  async fetchIDCode(): Promise<IDCode[]> {
    try {
      const data = await this.repository.fetchIDCode();
      return IDCodeAdapter.toIDCodeOutputDTO(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async addIDCode(): Promise<APIStatus> {
    try {
      const data = await this.repository.addIDCode();
      return IDCodeAdapter.toIDCodeStatus(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editIDCode(req: EditIDCodeReq): Promise<APIStatus> {
    try {
      const input_dto = IDCodeAdapter.toEditIDCodeInputDTO(req);
      const data = await this.repository.editIDCode(input_dto);
      return IDCodeAdapter.toIDCodeStatus(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async deleteIDCode(req: DeleteIDCodeReq): Promise<APIStatus> {
    try {
      const input_dto = IDCodeAdapter.toDeleteIDCodeInputDTO(req);
      const data = await this.repository.deleteIDCode(input_dto);
      return IDCodeAdapter.toIDCodeStatus(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // NODE
  async fetchNode(): Promise<Node[]> {
    try {
      const data = await this.repository.fetchNode();
      return NodeAdapter.toNodeOutputDTO(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editNode(req: EditNodeReq): Promise<APIStatus> {
    try {
      const input_dto = NodeAdapter.toEditNodeInputDTO(req);
      const data = await this.repository.editNode(input_dto);
      return NodeAdapter.toNodeStatus(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async fetchNodeHistogram(req: NodeHistogramReq): Promise<Histogram[]> {
    try {
      return await this.repository.fetchNodeHistogram(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // GROUP
  async fetchGroup(): Promise<Group[]> {
    try {
      const data = await this.repository.fetchGroup();
      return GroupAdapter.toGroupOutputDTO(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async addGroup(req: AddGroupReq): Promise<APIStatus> {
    try {
      const data = await this.repository.addGroup(req);
      return GroupAdapter.toGroupStatus(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editGroup(req: EditGroupReq): Promise<APIStatus> {
    try {
      const data = await this.repository.editGroup(req);
      return GroupAdapter.toGroupStatus(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async deleteGroup(req: DeleteGroupReq): Promise<APIStatus> {
    try {
      const input_dto = GroupAdapter.toDeleteGroupInputDTO(req);
      const group = await this.repository.deleteGroup(input_dto);
      return GroupAdapter.toGroupStatus(group);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // STORAGE
  async checkFile(): Promise<FileInfo[]> {
    try {
      return await this.repository.checkFile();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async uploadFile(req: UploadFileReq): Promise<APIStatus> {
    try {
      const data = await this.repository.uploadFile(req);
      return StoreAdapter.toStoreProcessStatus(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async storeFile(req: StoreFileReq): Promise<APIStatus> {
    try {
      const data = await this.repository.storeFile(req);
      return StoreAdapter.toStoreProcessStatus(data);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  // DATASET
  async fetchDataset(): Promise<any> {
    try {
      return await this.repository.fetchDataset();
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async addDataset(req: any): Promise<boolean> {
    try {
      return await this.repository.addDataset(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async editDataset(req: any): Promise<boolean> {
    try {
      return await this.repository.editDataset(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }

  async deleteDataset(req: any): Promise<boolean> {
    try {
      return await this.repository.deleteDataset(req);
    } catch (error) {
      this.error(error);
      throw error;
    }
  }
}
