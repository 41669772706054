import * as React from "react";

import { useCookies } from "react-cookie";

import { useDialog } from "../components/ui/use-dialog";

import { TrialDialog } from "../pages/auth/_modal/trial";
import { LogoDialog } from "../pages/auth/_modal/logo";

import { LoginUser } from "../core/auth/type/login-user";
import { useAuth } from "../hooks/auth";

type AuthContextType = {
  token: string | null;
  loginUser: LoginUser;
  logOut: () => void;
  isLogin: boolean;
  isLoading: boolean;
};

export const initialState: LoginUser = {
  id: null,
  role: 0,
  email: null,
  client: {
    id: null,
    name: null,
    logo: null,
    plan: {
      active: false,
      fee_type: "capacity",
      app_linkage: false,
      limit_size: null,
      options: [],
    },
    payment: { method: "card" },
  },
  trial: { status: null, expire: null },
};

const AuthContext = React.createContext<AuthContextType>({
  token: null,
  loginUser: initialState,
  logOut: () => {},
  isLogin: false,
  isLoading: true,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const [cookies] = useCookies(["trial", "logo"]);

  const trialDialog = useDialog();
  const logoDialog = useDialog();

  React.useEffect(() => {
    if (!auth.isLoading && auth.isLogin && auth.loginUser.client.id) {
      const currentTime = new Date();
      if (
        auth.loginUser.trial.status === 0 &&
        typeof auth.loginUser.trial.expire === "number" &&
        auth.loginUser.trial.expire > currentTime.getTime() &&
        auth.loginUser.trial.expire - 7 * 24 * 60 * 60 * 1000 <=
          currentTime.getTime()
      ) {
        let trial = cookies.trial || 0;
        if (currentTime > trial) {
          trialDialog.open();
        }
      }

      if (!auth.loginUser.client.logo) {
        let logo = cookies.logo || 0;
        if (currentTime > logo) {
          logoDialog.open();
        }
      }
    }
  }, [
    auth.loginUser,
    auth.isLoading,
    auth.isLogin,
    trialDialog,
    logoDialog,
    cookies,
  ]);

  return (
    <AuthContext.Provider value={auth}>
      {children}
      {trialDialog.isOpen && (
        <TrialDialog
          open={trialDialog.isOpen}
          onOpenChange={trialDialog.open}
        />
      )}
      {logoDialog.isOpen && (
        <LogoDialog open={logoDialog.isOpen} onOpenChange={logoDialog.open} />
      )}
    </AuthContext.Provider>
  );
};

export function useAuthentication() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthentication must be used within an AuthProvider");
  }
  return context;
}
