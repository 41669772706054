"use client";

import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { toast } from "sonner";

import { Button } from "../../../components/ui/button";
import { Icons } from "../../../components/icons";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

import { formatUnixTime } from "../../../libs/helper";
import { useAuthentication } from "../../../context/auth";
import { useUsecases } from "../../../store";

interface TrialDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function TrialDialog({ open, onOpenChange }: TrialDialogProps) {
  const { loginUser } = useAuthentication();
  const { authUsecase } = useUsecases();
  const { t } = useTranslation();

  const [, setCookie] = useCookies(["trial"]);

  const trial = loginUser.trial.expire?.toString();
  const trialExpire = trial ? formatUnixTime(trial) : "N/A";

  const { mutate: keepTrial } = useMutation({
    mutationFn: () => authUsecase.keepTrial(),
    onSuccess: () => {
      toast.dismiss();
      toast(t("auth-trial-toast-success"), {
        icon: <Icons.Check className="w-4 h-4" />,
        style: { background: "#0e2c58", color: "#ffffff" },
      });
      onOpenChange(false);
    },
    onError: () => {
      toast.dismiss();
      toast(t("auth-trial-toast-error"), {
        icon: <Icons.AlertCircle className="w-4 h-4" />,
        style: { background: "#ef8262", color: "#ffffff" },
      });
      onOpenChange(false);
    },
  });

  const { mutate: endTrial } = useMutation({
    mutationFn: () => authUsecase.skipTrial(),
    onSuccess: () => {
      toast.dismiss();
      toast(t("auth-trial-toast-success"), {
        icon: <Icons.Check className="w-4 h-4" />,
        style: { background: "#0e2c58", color: "#ffffff" },
      });
      onOpenChange(false);
    },
    onError: () => {
      toast.dismiss();
      toast(t("auth-trial-toast-error"), {
        icon: <Icons.AlertCircle className="w-4 h-4" />,
        style: { background: "#ef8262", color: "#ffffff" },
      });
      onOpenChange(false);
    },
  });

  const handleSkipTrialSubmit = () => {
    const expire = new Date();
    expire.setDate(expire.getDate() + 7);
    setCookie("trial", expire, {
      expires: expire,
      path: "/",
      secure: true,
    });
    toast(t("auth-trial-toast-success"), {
      icon: <Icons.Check className="w-4 h-4" />,
      style: { background: "#0e2c58", color: "#ffffff" },
    });
    onOpenChange(false);
  };

  const handleKeepTrialSubmit = () => keepTrial();

  const handleEndTrialSubmit = () => endTrial();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[640px] justify-center border border-gray-100 dark:border-white/10 rounded-xl">
        <div className="flex flex-col w-full px-10">
          <DialogHeader>
            <DialogTitle>{t("auth-trial-title")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col w-full py-10">
            <div className="w-full rounded-md bg-black/5 dark:bg-black/50 p-6 overflow-x-auto">
              <code>
                <pre className="grid gap-1 text-sm text-black dark:text-white [&_span]:h-4">
                  {trialExpire}
                </pre>
              </code>
            </div>
            <p className="pt-5">
              {t("auth-trial-message-1")}
              <br />
              {t("auth-trial-message-2")}
            </p>
          </div>
          <div className="flex flex-row justify-between gap-20">
            <Button
              type="button"
              variant="headless"
              size="headless"
              onClick={() => handleEndTrialSubmit()}
              className="flex flex-row h-10 w-[120px] gap-2 items-center justify-center rounded-lg text-xs font-medium text-white bg-[#ef8262] hover:bg-[#ef8262]/80"
            >
              {t("auth-trial-submit-1")}
            </Button>
            <div className="flex flex-row gap-5">
              <Button
                type="button"
                variant="headless"
                size="headless"
                onClick={() => handleSkipTrialSubmit()}
                className="flex flex-row h-10 w-[120px] gap-2 items-center justify-center rounded-lg text-xs font-medium text-white bg-[#0195c5] hover:bg-[#0195c5]/80"
              >
                {t("auth-trial-submit-2")}
              </Button>
              <Button
                type="button"
                variant="headless"
                size="headless"
                onClick={() => handleKeepTrialSubmit()}
                className="flex flex-row h-10 w-[120px] gap-2 items-center justify-center rounded-lg text-xs font-medium text-white bg-[#0195c5] hover:bg-[#0195c5]/80"
              >
                {t("auth-trial-submit-3")}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
