import { apiClient } from "../../libs/api";

export class AuthGateway {
  // LOGIN
  async loginAccount(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signin`;
    const res = await apiClient.post({
      url: url,
      body: req,
      useToken: false,
    });
    return res as any;
  }

  async setupAccount(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signup`;
    const res = await apiClient.post({
      url: url,
      body: req,
      useToken: false,
    });
    return res as any;
  }

  async setupVerify(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signup/confirm`;
    const res = await apiClient.post({
      url: url,
      body: req,
      useToken: false,
    });
    return res as any;
  }

  // MFA
  async setupMFA(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signin/mfa/setup`;
    const res = await apiClient.post({
      url: url,
      body: req,
      useToken: false,
    });
    return res as any;
  }

  async verifyMFA(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/signin/mfa`;
    const res = await apiClient.post({
      url: url,
      body: req,
      useToken: false,
    });
    return res as any;
  }

  // FORGET
  async resetPassword(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/forgot`;
    const res = await apiClient.post({
      url: url,
      body: req,
      useToken: false,
    });
    return res as any;
  }

  async verifyPassword(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/forgot/confirm`;
    const res = await apiClient.post({
      url: url,
      body: req,
      useToken: false,
    });
    return res as any;
  }

  // TERMS
  async checkTerms(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/auth/terms`;
    const res = await apiClient.get({
      url: url,
      useToken: true,
      token: req,
    });
    return res as any;
  }

  async verifyTerms(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_PLATFORM_URL}/xcausal/v1/auth/terms`;
    const res = await apiClient.post({
      url: url,
      useToken: true,
      token: req,
    });
    return res as any;
  }

  // USER
  async fetchClient(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor`;
    const res = await apiClient.get({ url: url, token: req.token });
    return res as any;
  }

  async fetchUser(req: any): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/members`;
    const res = await apiClient.get({ url: url, token: req.token });
    return res as any;
  }

  // TRIAL
  async keepTrial(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/trial`;
    const res = await apiClient.post({ url: url });
    return res as any;
  }

  async skipTrial(): Promise<any> {
    const url = `${process.env.REACT_APP_XCAUSAL_SERVICE_URL}/api/vw/vendor/trial`;
    const res = await apiClient.remove({ url: url });
    return res as any;
  }
}
