const shared = {
  "metadata-error-1": "ユーザ数が解析にあたり不十分のため、解析できません。",
  "metadata-error-2":
    "データをロード中にエラーが発生しました。 後でもう一度やり直してください。",

  "copied-success": "正常にコピーできました。",
  "download-success": "正常にダウンロードできました。",
};

export default shared;
