const dashboard = {
  "dashboard-api-error-1":
    "Analysis cannot be performed because the number of users is insufficient for analysis.",
  "dashboard-api-error-2":
    "The number of users is insufficient for analysis, so please reconfigure the filtering conditions.",
  "dashboard-api-error-3":
    "Since there are 0 selectable outcome nodes, please reconfigure the filtering conditions.",
  "dashboard-api-error-4":
    "Since there are 0 related nodes that can be selected, please set the filtering conditions again.",
  "dashboard-api-error-5":
    "The number of users is insufficient for analysis, so please reconfigure the filtering conditions.",

  "dashboard-ranking-label": "Ranking",
  "dashboard-demographics-label": "Demographics",
  "dashboard-trend-label": "Trend",
  "dashboard-symptom-ranking-label": "Symptom Ranking",
  "dashboard-relevance-ranking-label": "Relevance Ranking",
  "dashboard-condition-value-label": "Condition Value",
  "dashboard-group-label": "Group",
  "dashboard-distribution-label": "Distribution",
  "dashboard-items-label": "Cases",
  "dashboard-subjects-label": "",
  "dashboard-incidents-label": "Cases",
  "dashboard-large-data-label": "Lots of Data (sufficient for analysis)",
  "dashboard-sufficient-data-label": "Data Exists (sufficient for analysis)",
  "dashboard-insufficient-data-label": "Little Data (analysis not recommended)",
  "dashboard-degree-relevance-label": " Degree of Relevance",
  "dashboard-relationship-label": "Trend",
  "dashboard-time-series-label": "Time-series Comparison",
  "dashboard-relevance-data-label": "Comparison Over the Same Period",
  "dashboard-heatmap-body-front-label": "Front",
  "dashboard-heatmap-body-back-label": "Back",
  "dashboard-demographics-ratio-label": "Ratio",
  "dashboard-demographics-condition-level-label": "Demographic",
  "dashboard-related-axis-settings": "Settings:",
  "dashboard-strongly-related-items": "& Strongly Related Items",
  "dashboard-subordinate": "other",
  "dashboard-kpi-title": "Key KPI Trends",
  "dashboard-kpi-popover-button": "Settings",
  "dashboard-kpi-reorder-reset-button": "Reset",
  "dashboard-kpi-reorder-save-button": "Save",
  "dashboard-kpi-reorder-max-message": "The maximum number of KPI items is 8.",
  "dashboard-kpi-reorder-min-message": "The minimum number of KPI items is 1.",

  "demographics-genders-label": "Genders",
  "demographics-ages-label": "Ages",
};

export default dashboard;
